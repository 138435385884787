.Home_TCV {
  padding-bottom: 32px;
  width: 100vw;
  overflow: hidden;
}

.Home_TCV .M1 {
  margin: 8px !important;
}

.Home_TCV h4 {
  text-align: center;
  margin: 26px 0 12px 0;
}

.LoginBox .RowCenter {
  align-items: center;
}

.LoginBox .RowCenter h4 {
  margin: 0 0 0 8px;
  color: white;
}

.LoginBox p {
  color: white;
  padding-bottom: 8px;
}

.LoginBox {
  width: calc(100% - 16px);
  margin: 0 8px 16px 8px !important;

  font-size: 16px;
  font-weight: bold;
  line-height: 28px;

  background-color: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid white;
  color: white !important;
}

.Home_TCV .GridSeis {
  margin: auto;
  width: calc(100% - 16px) !important;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  row-gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.Home_TCV .TrustInUs {
  background-color: #f1f1f1;
  margin-bottom: -32px;
  border-bottom: 2px solid #ccc;
  padding: 24px 8px;
}

.Home_TCV .TrustInUs .GridSeis img {
  max-width: 80%;
  margin-left: 10px;
}

.CarouselComits {
  max-width: 100vw;
}

.CarouselComits h4 {
  max-width: 100vw;
}

.CarouselComits .Comentario {
  margin-bottom: 44px;
}

.Home_TCV .CarouselBanner h4 {
  font-size: 20px;
  color: white;
}

.Home_TCV .CarouselBanner img {
  transform: scale(1.05);
  margin-bottom: 44px;
}

.CarouselPromotionalBanner {
  background-color: transparent;
  margin-bottom: -24px;
}

.CarouselPromotionalBanner .CarouselPromotionalBanner-Image {
  width: calc(100vw - 16px) !important;
  margin-bottom: 44px;
}
