@font-face {
  font-family: "GMedium";
  src: url("./Gilroy-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "GRegular";
  src: url("./Gilroy-Light.ttf");
  font-display: swap;
}

* {
  box-sizing: border-box;
  font-family: "GRegular";
  margin: 0;
  padding: 0;
  outline-color: transparent;
  list-style: none;
  outline: none;

  scroll-behavior: smooth;
  align-content: flex-start;
}

*::-webkit-scrollbar {
  width: 0;
  height: 0;
}

:root {
  --ColorFondo: #ebebeb; /* #fafafa */
  --ColorFooter: #f1f1f1;

  --CistralBlanco: rgba(255, 255, 255, 0.4);
  --CistralNegro: rgba(0, 0, 0, 0.5);

  --Blanco: #ffffff;
  --Blancoasd: #e5ddd0;

  --AzulMP: #1fa9e7; /* #1fa9e7 */
  --AzulSuave: #afe4ff85;
  --AzulOscuro: #008cc9;

  --VerdeMP: #01c167;
  --VerdeSuave: #e1fff1;
  --VerdeOscuro: #008b4a;

  --RojoMP: #ff6347;
  --RojoSuave: #ffe4e0;

  --DoradoMP: #ffd700;
  --DoradoSuave: #fff2a9;
  --DoradoOscuro: #b48a1e;

  --NegroMP: #414141;
  --NegroSuave: #555555;

  --GrisClaro: #cccccc;

  --SombraGlobal: #0000004d;

  /* ================ 
  
  --SizeXS: 8px;
  --SizeS:  11px;
  --SizeM:  14px;
  --SizeL:  16px;
  --SizeXL: 18px;

  test redifinición con gasti ================ */

  --SizeXS: 11px;
  --SizeS: 13px;
  --SizeM: 15px;
  --SizeL: 18px;
  --SizeXL: 20px;

  --IconSize: 25px;

  --AltoEstandar: 54px;
}

body {
  padding-top: 7px;
  background-color: var(--ColorFondo);
  display: flex;
  flex-flow: column;
  align-items: center;
}

a {
  color: var(--AzulMP);
  text-decoration: none;
}

button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GMedium";
}

h1::first-letter,
h2::first-letter,
h3::first-letter,
h4::first-letter,
h5::first-letter,
h6::first-letter {
  text-transform: capitalize;
}

/*=== :D  Auxiliares ==================================== */

.Plum {
  background-color: plum !important;
}

.Card {
  background-color: var(--Blanco);
  box-shadow: 0 0 20px -10px var(--SombraGlobal);

  border-radius: 8px;
  padding: 12px 8px;
  margin: 16px 0;
}

.pillBlue {
  background-color: var(--AzulSuave);
  padding: 8px 16px;
  border-radius: 8px;
  color: var(--AzulOscuro);
}

.pillBlue svg {
  margin-right: 16px;
}

.FixedBottom {
  width: calc(100% - 16px) !important;
  position: fixed;
  bottom: 0;
  left: 8px !important;
  z-index: 1;
}

.Invisible {
  opacity: 0;
}

.BlueBox {
  padding-top: 70px;
  background-color: var(--AzulMP);
}

.BG-Blue {
  background-color: var(--AzulMP) !important;
}

.ConAire {
  padding: 1rem;
}

.VisibleOnlyDesktop {
  display: none;
}

.Canvas {
  background-color: -gl !important;
  width: 100vw;
  min-height: calc(100vh - 64px);
  margin-top: 54px;
}

.btn-soltar {
  border: 2px solid var(--RojoMP);
  padding: 16px;
  font-size: var(--SizeL);
  color: var(--RojoMP);
  background-color: var(--RojoSuave);
  border-radius: 8px;
}

.RowCenter {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.ColumnCenter {
  display: flex !important;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.ShowElement {
  transition: all 0.3s;
  transform: scale(1) translateY(0) translateX(0) !important;
  opacity: 1 !important;
}

.SpaceBetween {
  width: 100%;
  justify-content: space-between !important;
}

.centrado {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0.8) translate(-63%, -60%);
}

.m1 {
  margin: 8px;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mb-2 {
  margin-top: 16px;
}

.pb-1 {
  padding-bottom: 8px;
}

.pt-1 {
  padding-top: 8px;
}

.p1 {
  padding: 8px !important;
}

.pid {
  padding: 0 8px !important;
}

.p2 {
  padding: 16px !important;
}

.mbn-1 {
  margin-bottom: -8px;
}

.Aire-100 {
  width: 100%;
  height: 100px;
}

.Aire-200 {
  width: 100%;
  height: 200px;
}

.JustifyCenter {
  justify-content: center !important;
}

.JustifyAround {
  justify-content: space-around !important;
}

.JustifyEnd {
  justify-content: flex-end !important;
}

.AlignCenter {
  align-items: center;
}

.TextCenter {
  text-align: center !important;
}

.TextLight {
  text-align: center !important;
}

.TextWhite {
  color: var(--Blanco);
}

.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}
.w80 {
  width: 80% !important;
}
.w100 {
  width: 100% !important;
}

.h100 {
  height: 100vh;
}

.AfixMobilIcon {
  margin-left: 4px !important;
}

.FixedBottom {
  position: fixed;
  bottom: 4px;
  left: 4px;
}

.Danger-Text {
  color: var(--RojoMP);
}

.Primary-Text {
  color: var(--AzulMP) !important;
}

.Separador {
  display: flex;
  justify-content: center;
  margin: 16px 0;

  background-color: #ddd;
  box-shadow: inset 0 -10px 0 white, inset 0 10px 0 white;
}

.Separador span {
  background-color: white;
  width: auto !important;
  padding: 0 8px !important;
  font-size: var(--SizeXL) !important;
  font-family: "GMedium";
  text-transform: capitalize;
}

/*=== :D BajateLaApp ==================================== */

.BajateLaApp {
  background-color: deeppink;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: white;
}

.BajateLaApp img {
  max-width: 54px;
  margin-right: 8px;
  border-radius: 12px;
}

.BajateLaApp svg {
  font-size: 24px;
}

.BajateLaApp h4 {
  background-color: transparent;
  font-size: var(--SizeL);
  width: 70vw;
  margin-right: 8px;
}

.BajateLaApp p {
  font-size: var(--SizeS);
  max-width: 80%;
}

.BajateLaApp .Estrellas {
  background-color: transparent;
  max-width: 80px;
  margin-top: 4px;
}

.BajateLaApp .Estrellas svg {
  color: var(--DoradoMP) !important;
  font-size: var(--SizeS);
}

/*=== :D  Navbar ==================================== */

.fixedNavbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: palegreen;
}

.MobileNavbar,
.NavbarGoBack {
  background-color: var(
    --AzulMP
  ); /* :D La navbar Mobile adquiere color mas abajo */

  height: 64px; /* :D al hacerse fija pasa a 64px */
  width: 100vw; /* :D El padding interno le da el ancho total del 100% del ancho del viewport */
  margin-top: -1px;
  transition: all 0.3s;
  padding: 0 16px;
}

.MobileNavbar {
  width: 100vw;
}

.NavbarGoBack img {
  height: 100%;
}

.NavbarGoBack button {
  border: none;
  font-size: var(--IconSize);
  background-color: transparent;
  color: var(--Blanco);
}

.NavbarGoBack button:active {
  transform: scale(0.8);
  opacity: 0.5;
}

.HomeNavbar .OliverLogo {
  margin: 0 16px 0 0;
  cursor: pointer;
}

/*=== :D filtros ==================================== */

.NavbarFilter .filterTag {
  background-color: var(--Blanco);
  border: none;
  margin-top: 8px;
  margin-right: 0.5rem;
  padding: 0.3rem 0.5rem;
  border-radius: 8px;
  opacity: 0.95;
  cursor: pointer;
}

.NavbarFilter .filterTag:hover {
  opacity: 1;
}

.NavbarFilter .filterTag:active {
  opacity: 0.8;
}

/*=== :D ArriveSafely ==================================== */

.ArriveSafely {
  width: 100%;
  background-color: ghostwhite;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  border-top: 2px solid var(--Blanco);
}

.ArriveSafely span {
  min-width: 120px;
  text-align: center;
  font-size: var(--SizeXS);
}

.ArriveSafely span strong {
  text-transform: uppercase;
}

.ArriveSafely legend {
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
  background-color: var(--AzulMP);
  justify-content: flex-end;
  padding: 8px 12px 8px 16px;
  font-size: var(--SizeXS);
  color: var(--Blanco);
  font-weight: bold;
}

.ArriveSafely img {
  width: 32px;
}

.ArriveSafely legend div {
  margin: 0 8px;
}

/*=== :D Sidebar ==================================== */

.OliverIcons button {
  color: red;
  border: none;
  width: 44px;
  height: 36px;
  font-size: 2rem;
  cursor: pointer;
  margin-left: 8px;
}

/*=== :D Sidebar ==================================== */
.Sidebar {
  position: relative;
}

.SidebarIcon,
.NavbarBackIcon {
  background-color: transparent;
  color: var(--Blanco);
  border: none;
  width: 32px;
  height: 32px;
  font-size: var(--IconSize);
  cursor: pointer;
}

.Sidebar ol {
  position: absolute;
  top: 46px !important;
  left: -16px;
  background-color: var(--Blanco);
  list-style: none;
  min-width: 300px;
  border-radius: 8px;
  box-shadow: 0 0 20px -10px var(--SombraGlobal);
  overflow: scroll;
  transition: all 0.3s !important;
  opacity: 1;
  z-index: 998;
}

.Sidebar ol li {
  border-bottom: 1px solid #fafafa;
  height: 54px;
}

.Sidebar ol li button {
  width: 100%;
  height: 100%;
  display: block;
  padding-left: 16px;
  text-align: left;
  border: none;

  background-color: var(--Blanco);
  color: var(--AzulMP);
  font-size: var(--SizeL);
  font-family: "GMedium";
}

.MobileNavbar .Sidebar ol {
  background-color: white;
  border-radius: 0;
  width: 100vw;
  height: calc(100vh - 54px);
  box-shadow: none;
  top: 64px;
  font-size: 18px;

  transform: translateX(-100vw);
}

/*=== :D Carrito ==================================== */
.Carrito {
  position: relative;
}

.CartMobileView {
  width: 100vw;
  padding-top: 8px;
  padding-left: 0;
  position: relative;
  margin-left: -16px;
}

.Carrito button {
  width: 32px;
  min-height: 32px;

  background-color: transparent;
  color: var(--Blanco);
  font-size: var(--IconSize);

  border: none;
  cursor: pointer;
}

.Carrito .svg-disabled {
  opacity: 0.7;
  cursor: no-drop;
}

.Carrito span {
  position: absolute;
  top: -12px !important;
  right: -16px !important;
  background-color: var(--DoradoMP);
  color: var(--DoradoOscuro);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
  line-height: 34px;
  font-weight: bold;
  transform: scale(0.6);
  font-family: "GMedium";
}

.Carrito svg {
  color: var(--Blanco);
}

.MobileNavbar .Carrito span {
  top: -4px;
  right: 0;
}

.Carrito .BoxCarrito {
  position: absolute;
  top: 43px;
  right: -16px;

  transform: translateX(-120vw);
  background-color: var(--Blanco);
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  transition: all 0.3s;

  max-height: calc(100vh - 196px);
}

.Carrito .BoxCarrito ul {
  background-color: var(--Blanco) !important;
  overflow-y: scroll;
  max-height: calc(100vh - 196px);
}

.Carrito .BoxCarrito .btn-carrito {
  background-color: var(--VerdeMP);
  width: calc(100vw - 2rem);
  margin: 8px 1rem 0 1rem;
  color: var(--Blanco);
  text-align: center;
  font-size: 16px !important;
  padding: 8px;
}

.Carrito .BoxCarrito .btn-carrito p {
  margin-top: 4px;
  font-size: 14px;
}

.Carrito .BoxCarrito h2 {
  text-align: center;
  margin: 32px 0 16px 0;
}

.Carrito .BoxCarrito p {
  text-align: center;
  margin: 0 0 8px 0;
}

/*=== :D Logo ==================================== */

.OliverLogo {
  background-color: transparent;
  max-width: 85px;
  max-height: 64px;
}

.OliverLogoFooter {
  background-color: transparent;
  max-width: 85px;
  max-height: 64px;
  margin-left: -15px;
}

.MobileLogoBox {
  background-color: transparent;
  margin: auto;
}
.MobileLogoBoxOpenApp {
  margin-top: 70px;
}
.MobileLogoBox .OliverLogo {
  max-height: 74px;
  max-width: 200px;
}
/*=== :D Burbujas Compu ==================================== */

.HomeBubbleBlock {
  background-color: transparent;
  width: 100vw;
  margin: 0 0 5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.HomeBubbleBlock li {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  margin: 0 0.5rem;
  background-color: var(--Blanco);
  box-shadow: 0 0 20px var(--SombraGlobal);

  display: flex;
  align-items: flex-end;
  justify-content: center;

  transform: scale(0.9);
  overflow: hidden;
  transition: all 0.3s;
}

.HomeBubbleBlock .anillado {
  box-shadow: 0 0 20px 10px var(--SombraGlobal);
  position: relative !important;
  transform: scale(1);
}

.HomeBubbleBlock .anillado::after {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: transparent;
  left: -5rem;
}

.HomeBubbleBlock li:hover {
  transform: scale(1);
}

.HomeBubbleBlock li a {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  line-height: 280px;
  text-align: center;
  overflow: hidden;
}
.HomeBubbleBlock li a img {
  top: 45%;
  height: 90px;
  background-color: transparent;
  z-index: 5;
}

.HomeBubbleBlock li a h4 {
  background-color: transparent;
  color: var(--NegroMP);
  font-weight: normal;
  z-index: 1;
  line-height: 215px;
  font-size: var(--SizeS);
}

.HomeBubbleBlock li button {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: none;
  background-color: rgba(221, 160, 221, 0);
}

/*=== :D Home Canvas, elementos ocultos ==================================== */

.homeCanvasBox {
  margin-top: 40px;

  width: 100vw;
  min-height: 120px;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  background-color: transparent;
}

.homeCanvasBox .Btn-OutlineWhite {
  position: absolute;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  color: white;
  padding: 8px 44px;
  font-weight: bold;
  transition: all 0.3s;
  background-color: var(--AzulMP);
}

.homeCanvasBox:hover .Btn-OutlineWhite {
  background-color: white;
  color: var(--AzulMP);
}

/*=== :D Boton de Login de la navbar ==================================== */

.LoginButton {
  background-color: var(--Blanco);
  color: var(--AzulMP);

  height: 40px;

  margin: 0 16px;
  padding: 0 32px;
  border: none;
  border-radius: 32px;
  font-size: 0.9rem;
  font-weight: bold;
  box-shadow: 0 0 20px -5px var(--SombraGlobal);
  cursor: pointer;
}

.LoginButton:hover {
  background-color: #fafafa;
}

.LoginButton span {
  text-transform: capitalize;
}

/*=== :D Facebook Boton Login  ==================================== */

.fbLoginBtn {
  padding: 0.5rem;

  background-color: #3b5998 !important;
  border: none;
  border-radius: 32px;

  justify-content: center;
}

.fbLoginBtn img {
  width: 28px;
  margin-right: 16px;
}

.fbLoginBtn span {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--Blanco);
}

.fbLoginBtn:hover {
  color: var(--Blanco) !important;
  cursor: pointer;
}

/*=== :D Caja de Busqueda ==================================== */
.SearchView {
  background-color: var(--Blanco);
  min-height: 100vh;
  width: 100vw;
}

.InputSearchBox {
  height: 44px;
  width: 320px;
  padding-right: 8px;

  color: var(--AzulSuave);
  position: relative;
}

.SearchView .SearchBox .InputSearchBox {
  margin-top: 54px;
  height: 54px;
  width: 100vw;
  background-color: var(--AzulSuave);
}

.HomeNavbar .InputSearchBox {
  border-radius: 8px;
}

.MobileNavbar .InputSearchBox {
  width: 100vw;
  border-radius: 0;
  padding-right: 16px;
  background-color: var(--AzulSuave);
  height: 54px;
}

.HomeNavbar .InputSearchBox {
  background-color: var(--Blanco);
}

.SearchBox div .btn-ShowSearchbox {
  position: absolute;
  background-color: transparent !important;
  width: 100%;
  height: 44px;

  cursor: pointer;
}

.InputSearchBox input {
  width: 100%;
  height: 100%;

  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  padding: 0 16px;

  background-color: transparent;
  font-size: 16px;
  color: black;
}

.SearchBox input::placeholder {
  color: var(--AzulMP);
  font-size: 14px;
}

.MobileNavbar .SearchBox {
  border-bottom: 1px solid #d3f1ff;
}

.SearchBox button {
  border: none;
  color: var(--AzulMP);
  height: 32px;
  width: 32px;
  background-color: transparent;
  font-size: 20px;
}

.HomeNavbar .SearchBox ol {
  position: absolute;
  top: 90px;
  left: 10px;
  width: 450px;
  max-height: 390px;

  background-color: var(--Blanco);

  overflow-y: scroll;
  z-index: 100;
}

.fixedNavbar .SearchBox ol {
  top: 70px !important;
}

.HomeNavbar .SearchBox ol {
  box-shadow: 0 0 20px -5px var(--SombraGlobal);
  border-radius: 8px;
}

.MobileNavbar .SearchBox div svg {
  color: var(--AzulMP);
}

.MobileNavbar .SearchBox ol {
  background-color: var(--Blanco);
  height: calc(100vh - 120px);
  width: 100%;
  left: 0;
  top: 54px !important;
  overflow-y: scroll;
}

.SearchBox ol li {
  padding: 4px;
  border-bottom: 1px solid var(--ColorFondo);
  min-height: 80px;
}

.MobileNavbar .SearchBox ol li {
  /*height: 84px;*/
  padding: 8px;
}

.SearchBox ol li:hover {
  background-color: rgba(31, 168, 231, 0.05);
  cursor: pointer;
}

.SearchBox ol li .ImageBox {
  background-color: transparent;
  min-width: 44px;
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.SearchBox ol li img {
  max-width: 32px !important;
  max-height: 54px !important;
}

.SearchBox ol li .LabelBox {
  background-color: transparent;
  width: 100%;
  height: 64px;
  padding: 4px 4px 4px 4px;
}

.LabelBox h4 {
  font-size: var(--SizeM);
  color: var(--NegroSuave);
}

.LabelBox p {
  margin-top: 4px;
  font-size: var(--SizeS);
  color: var(--NegroSuave);
}

.LabelBox span {
  margin: 8px 0 0 0 !important;
  font-size: var(--SizeS);
  color: var(--NegroSuave);
}

/* width */
.HomeNavbar .SearchBox ol::-webkit-scrollbar {
  height: 0;
  width: 8px;
}

/* Track */
.HomeNavbar .SearchBox ol::-webkit-scrollbar-track {
  background: transparent !important;
  margin: 8px 0 !important;
}

/* Handle */
.HomeNavbar .SearchBox ol::-webkit-scrollbar-thumb {
  background: var(--NegroSuave);
  transition: all 0.3s;
}

/* Handle on hover */
.HomeNavbar .SearchBox ol::-webkit-scrollbar-thumb:hover {
  background: var(--AzulMP);
}

.OpenSearch {
  position: relative;
  border-radius: 50%;
  transition: all 0.3s;
}

.OpenSearch:active {
  background-color: var(--Blanco);
  animation: boom 3s;
}

@keyframes boom {
  0% {
    box-shadow: 0 0 0 -20px var(--Blanco);
    transition: all 0.3s;
  }
  100% {
    background-color: var(--Blanco);
    box-shadow: 0 0 0 2000px var(--Blanco);
    transition: all 0.3s;
  }
}

/*=== :D  Estilos para Mobile o Tablet === NO SON MEDIA QUERYS, ESOS ESTAN MAS ABAJO !! ================================= */

.MobileCanvas {
  background-color: transparent;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  margin: 1.5rem 0;
}

.MobileCanvas ul {
  background-color: transparent;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100vw;
  max-width: 400px;

  padding: 0 6px;
}

.MobileCanvas ul:first-child,
.MobileCanvas ul:last-child {
  justify-content: space-around;
  width: 80%;
  max-width: 300px;
  margin: 4px 0; /* Se debe ajustar cuando ya este PetCarrusel*/
}

.borderRadius {
  border-radius: 8px;
}
.MobileCanvas ul li {
  width: 26vw;
  height: 26vw;
  border-radius: 50%;
  box-shadow: 0 0 20px -5px var(--SombraGlobal);

  display: flex;
  justify-content: center;
  align-items: center;
}

.MobileCanvas ul li a {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--Blanco);
}
.MobileCanvas ul li a img {
  max-height: 55%;
  margin-top: -8px;
}

.MobileCanvas ul li a h4 {
  line-height: 40vw;
  font-size: var(--SizeS);
  background-color: transparent;
  text-align: center;
  color: var(--NegroMP);
  font-weight: normal;
}

.MobileCanvas ul:nth-child(2) .MobileListItem {
  background-color: transparent !important;
}

.MobileCanvasAnimation #first {
  animation: first 1s;
  animation-delay: 0.1s;
}
.MobileCanvasAnimation #second {
  animation: second 1s;
  animation-delay: 0.2s;
}
.MobileCanvasAnimation #third {
  animation: third 1s;
  animation-delay: 0.3s;
}
.MobileCanvasAnimation #fourth {
  z-index: 0;
  animation: fourth 1s;
  animation-delay: 0.4s;
}
.MobileCanvasAnimation #fifth {
  z-index: 0;
  animation: fifth 1s;
  animation-delay: 0.5s;
}
.MobileCanvasAnimation #sixth {
  z-index: 0;
  animation: sixth 1s;
  animation-delay: 0.6 s;
}

/* Form dentro del Alta de domicilio ! ===================== */

.autocomplete-dropdown-container {
  display: block;
  width: 100%;
  margin-top: -16px;
  margin-bottom: 16px;
}

.autocomplete-dropdown-container div {
  width: 100%;
  background-color: var(--AzulSuave);
  margin: 4px 0;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.autocomplete-dropdown-container span {
  font-size: 85%;
  color: var(--AzulMP);
}

.Form-Loading {
  background-color: transparent !important;
}

.Form-Loading .LoadingSpinner img {
  background-color: var(--Blanco);
  width: 32px;
  height: 32px;
}

.AddressMap {
  width: 100%;

  height: 200px !important;
  max-width: 500px;
  margin-left: 0 !important;

  border-radius: 16px;
}

.NewAddress {
  position: relative;
  background-color: var(--Blanco);
  margin-top: 54px;
  min-height: calc(100vh - 54px);
}

.DrawerBox .NewAddress {
  margin-top: 0;
}

.NewAddress form {
  background-color: transparent;
  padding: 16px 8px;
  box-shadow: none;
  width: 100%;
}

.NewAddress form h2 {
  padding: 0 0 16px 0;
  text-align: center;
  width: 100%;
  background-color: transparent;
  font-size: 20px;
}

.NewAddress fieldset {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.NewAddress fieldset label {
  background-color: transparent;
  display: block;
  padding: 8px 0;
}
.NewAddress fieldset input {
  border-radius: 4px;
  padding: 16px 8px 16px 12px;
  background-color: var(--AzulSuave);
  width: 100%;
  border: none;
  margin-bottom: 16px;
}

.NewAddress fieldset .RowCenter input {
  width: calc(50% - 4px) !important;
}

.NewAddress .Btn-Action {
  width: calc(100% - 8px);
}

.NewAddress .Mapa {
  margin: 16px 0 100px 0;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
}

.leaflet-container {
  z-index: 1;
}

.NewAddress .Mapa .leaflet-control-attribution {
  display: none;
}

/*=== :D Animaciones ==================================== */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes goUp {
  from {
    margin-top: 5rem;
  }
  to {
    margin-top: 0;
  }
}

@keyframes goDown {
  from {
    margin-top: 0;
  }
  to {
    margin-top: 5rem;
  }
}

@keyframes Giro360 {
  0% {
    transform: rotate(0);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

@keyframes HeartbeatAnimation {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes xpand {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes PurchaseMadeAnimation {
  0% {
    opacity: 0;
    height: 110vh;
  }
  100% {
    opacity: 1;
    height: 200px;
  }
}

@keyframes UpAndDown {
  0% {
  }
  50% {
    padding-top: 0.5rem;
  }
  100% {
  }
}

@keyframes succesPay {
  0% {
    box-shadow: 0 5px 20px -20px transparent,
      -500px 0 0 inset var(--VerdeOscuro);
  }
  15% {
    box-shadow: 0 5px 20px -20px transparent,
      -250px 0 0 inset var(--VerdeOscuro);
  }
  25% {
    box-shadow: 0 5px 20px -20px transparent,
      -100px 0 0 inset var(--VerdeOscuro);
  }
  75% {
    box-shadow: 0 5px 20px -20px transparent, -50px 0 0 inset var(--VerdeOscuro);
  }
  100% {
    box-shadow: 0 5px 20px -20px transparent, 0 0 0 inset var(--VerdeOscuro);
  }
}

@keyframes declinedPay {
  0% {
    background-color: var(--VerdeMP);
    box-shadow: 0 5px 20px -20px transparent,
      -500px 0 0 inset var(--VerdeOscuro);
  }
  15% {
    background-color: var(--VerdeMP);
    box-shadow: 0 5px 20px -20px transparent,
      -250px 0 0 inset var(--VerdeOscuro);
  }
  25% {
    background-color: var(--VerdeMP);
    box-shadow: 0 5px 20px -20px transparent,
      -100px 0 0 inset var(--VerdeOscuro);
  }
  75% {
    background-color: var(--RojoMP);
    box-shadow: 0 5px 20px -20px transparent, -50px 0 0 inset var(--VerdeOscuro);
  }
  100% {
    background-color: var(--RojoMP);
    box-shadow: 0 5px 20px -20px transparent, 0 0 0 inset var(--VerdeOscuro);
  }
}

@keyframes aparece {
  0% {
    margin-top: 5rem;
    opacity: 1;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes Giro180Y {
  0% {
    transform: perspective(700px) rotateY(180deg);
  }
  100% {
    transform: perspective(700px) rotateY(0deg);
  }
}

@keyframes CardFlipPresentation {
  0% {
    transform: perspective(700px) rotateY(35deg);
  }

  50% {
    transform: perspective(700px) rotateY(-35deg);
  }

  100% {
    transform: perspective(700px) rotateY(0deg);
  }
}

@keyframes first {
  0% {
    transform: translateX(80%) translateY(100%) scale(0.1);
    opacity: 0;
  }
}
@keyframes second {
  0% {
    transform: translateX(-80%) translateY(100%) scale(0.1);
    opacity: 0;
  }
}
@keyframes third {
  0% {
    transform: translateX(-80%) translateY(0) scale(0.1);
    opacity: 0;
  }
}
@keyframes fourth {
  0% {
    transform: translateX(-80%) translateY(-100%) scale(0.1);
    opacity: 0;
  }
}
@keyframes fifth {
  0% {
    transform: translateX(80%) translateY(-100%) scale(0.1);
    opacity: 0;
  }
}
@keyframes sixth {
  0% {
    transform: translateX(80%) translateY(0) scale(0.1);
    opacity: 0;
  }
}

/*=== :D Modal ==================================== */

.CustomModal {
  background-color: var(--CistralNegro);

  z-index: 50000;
  width: 100vw;
  height: 100vh;
  position: fixed;

  top: 0;
  left: 50%;
  transform: translateX(-50%);

  animation-name: fadeIn;
  animation-duration: 0.3s;

  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.ModalBody {
  position: relative;

  background-color: var(--Blanco) !important;
  box-shadow: 0 0 20px var(--SombraGlobal);
  border-radius: 8px;

  padding: 0 0 0 0;

  width: 90vw;
  min-height: 200px;

  max-width: 500px;
  max-height: 92vh;
  z-index: 1000;
}

.MobileNavbar .Active-ModalForm {
  margin-bottom: 1rem;
}

.Open {
  animation-name: fadeIn;
  animation-duration: 0.3s;
}

.Open .ModalBody {
  animation-name: goUp;
  animation-duration: 0.5s;
}

.BtnCloseModal {
  position: absolute;
  top: -22px;
  left: -22px;

  background-color: var(--RojoMP) !important;
  color: var(--Blanco) !important;
  border: none;

  width: 44px !important;
  height: 44px !important;
  font-size: 2rem;
  transition: all 0.1s;
  cursor: pointer;

  border-radius: 50%;

  transform: scale(0.6);

  z-index: 50000;
}

.ModalBody h4 {
  background-color: transparent;
  text-align: center;
  font-size: 100%;
  padding: 1rem 0 1rem 0;
}

/*=== :D Direcciones  ==================================== */

.MobileNavbar .BtnAddress,
.HomeNavbar .BtnAddress {
  position: relative;
  background-color: var(--CistralBlanco);
  padding: 0 16px !important;
  justify-content: flex-end;
  width: 60vw !important;
  height: 40px;
}

.MobileNavbar .BtnAddress {
  height: 36px;
}

.BtnAddress:active {
  background-color: var(--CistralBlanco) !important;
}

.MobileNavbar .BtnAddress p,
.HomeNavbar .BtnAddress p {
  width: 100%;
  margin-right: 16px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-family: "GMedium";
}

.MobileNavbar .ListAddress,
.HomeNavbar .ListAddress {
  position: absolute;
  z-index: 1000;
}

.MobileNavbar .ListAddress {
  top: 45px;
  left: 50%;
  transform: translateX(-150%) !important;
  background-color: var(--Blanco);
  width: 100vw;
  height: 100vh;
  transition: all 0.3s;
  z-index: 1;
}

.MobileNavbar .UserAddress .ShowElement {
  transform: translateX(-50%) !important;
}

.MobileNavbar .ListAddress li,
.HomeNavbar .ListAddress li {
  background-color: var(--Blanco);
  color: var(--AzulMP) !important;

  padding: 0 24px 0 16px;
  border-bottom: 2px solid whitesmoke;
  cursor: pointer;
}

.MobileNavbar .ListAddress li {
  height: 54px;
  line-height: 54px;
}

.MobileNavbar .ListAddress li h5 {
  font-size: var(--SizeL);
  max-width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MobileNavbar .ListAddress li b,
.HomeNavbar .ListAddress li b {
  display: block;
  width: 100%;

  background-color: transparent;
  text-align: left;
  padding-top: 4px;
  margin-bottom: -4px;
}

.HomeNavbar .BtnAddress {
  max-width: 250px;
  padding: 12px 16px !important;
}

.HomeNavbar .ListAddress {
  top: 55px;
  right: -25px;

  min-height: 44px;
  width: 300px;

  background-color: var(--Blanco);
  border-radius: 8px;
  box-shadow: 0 0 20px -5px var(--SombraGlobal);
  overflow: hidden;

  transform: translateY(-100px) scale(0);
  opacity: 0;
}
.HomeNavbar .ListAddress li {
  padding: 0.8rem 1rem;
}

.HomeNavbar .ListAddress li h5 {
  display: block;
  width: 200px;
  text-align: left;
  font-size: 1rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.HomeNavbar .ListAddress li b {
  font-size: 0.8rem;
}

/*=== :D Botones  ==================================== */

.Btn {
  border: none;
  width: 100%;
  padding: 0.5rem 2rem; /* :D Este valor se ajusta para computadora en con la Media Query, NO TOCAR! */
  font-size: 1rem;
  border-radius: 32px;
  color: var(--Blanco);
  text-align: center;
}

.BtnDisable {
  background-color: #ddd !important;
  color: grey !important;
  font-size: 1.3rem !important;
  box-shadow: none !important;
}

.BtnDisable:hover {
  color: grey;
  cursor: no-drop;
}

.BtnDisable:active {
  background-color: #ddd;
}

.Btn-OutlineWhite {
  border: 2px solid var(--Blanco);
  cursor: pointer;
  background-color: transparent;
  padding: 8px;
  border-radius: 8px;
}

.Btn-OutlineWhite:active {
  transform: scale(0.8);
  opacity: 0.5;
}

.BtnPrimary {
  background-color: var(--AzulMP);
  color: var(--Blanco) !important;
}

.BtnPrimary:hover {
  color: var(--Blanco);
  cursor: pointer;
  box-shadow: 0 0 20px -16px var(--AzulOscuro);
}

.BtnPrimary:active {
  background-color: var(--AzulOscuro);
}

.Btn-MasoMenos {
  width: 100%;
  height: 54px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  border-radius: 8px;
}

.Btn-MasoMenos button,
.Btn-MasoMenos p {
  width: 33.3%;
  border: none;
  font-size: 20px;
  text-align: center;
}

.Btn-MasoMenos button {
  height: 55px;
  color: var(--AzulMP);
  background-color: whitesmoke;
}

.Btn-MasoMenos p {
  width: 37% !important;
  height: 55px;

  line-height: 55px;
  font-family: "GMedium";
  font-size: 24px;

  box-shadow: 0 4px 0 whitesmoke inset, 0 -4px 0 whitesmoke inset;
}

.Btn-MasoMenos button:active {
  opacity: 0.59;
}

.btn-CompraVerde,
.btn-Disabled {
  border: none;
  border-radius: 8px;
  height: 54px;
  width: 100%;

  background-color: var(--VerdeMP);
  box-shadow: 0 0 10px transparent;
  cursor: pointer;
  color: var(--Blanco);

  font-family: "GMedium";
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}

.btn-Disabled {
  background-color: lightgray;
  box-shadow: 0 0 20px -10px gray;
  cursor: no-drop;
  color: gray;
}

.btn-CompraVerde small {
  font-size: var(--SizeS);
  font-weight: 900;
  text-transform: initial;
}

.Btn-Small {
  height: 32px !important;
}

.btn-blue {
  background-color: var(--AzulMP) !important;
}

.HomeNavbar {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);

  width: 85vw;
  max-width: 1366px;
  background-color: var(--AzulMP);
}

.HomeNavbar .btn-delete,
.MobileNavbar .btn-delete,
.btn-delete {
  border: none;
  background-color: transparent;
  font-size: 1.2rem;
  margin-right: -1rem;
  width: 48px;
  height: 100%;
}

.MobileNavbar .btn-delete svg,
.btn-delete svg {
  color: var(--RojoMP) !important;
}

.Btn-Link {
  padding: 0 0.5rem;
  color: var(--AzulMP);
  background-color: transparent;
  border: none;
  transition: all 0.5s;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}
.Btn-Link:active {
  color: var(--AzulOscuro);
}

.Btn-Action {
  border: none;
  background-color: var(--VerdeMP);
  color: var(--Blanco);

  border-radius: 8px;
  font-family: "GMedium";
  font-size: var(--SizeL);

  height: 54px;
}

/*=== :D Nuestras Marcas  ==================================== */

.BrandContainer h1 {
  color: var(--Blanco);
  font-size: var(--SizeL);
  text-align: center;
  margin-bottom: 4px;
  letter-spacing: 2px;
}

.BrandContainer h5 {
  color: var(--Blanco);
  font-size: var(--SizeS);
  text-align: center;
  letter-spacing: 2px;
  font-family: "GRegular";
}
.BrandContainer {
  width: 100vw;
  margin-bottom: 20px;
  background-color: transparent;
}

.BrandContainer ol {
  width: 100%;
  background-color: transparent;

  display: flex;
  flex-wrap: nowrap;
  overflow-y: scroll !important;
}

.BrandContainer ol li {
  margin-right: -2px;
}

.BrandBubble {
  position: relative;
  width: 128px;
  height: 128px;
  background-color: var(--Blanco);
  border: none;
  margin: 6px;
  display: block;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 15px -10px var(--SombraGlobal);
  transform: scale(0.95);
  transition: all 0.3s;
}

.BrandBubble:hover {
  transform: scale(1.05);
}

.BrandBubble img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 82%;
  border-radius: 50%;
}

.BrandBubbleTag {
  position: absolute;
  width: 100%;
  bottom: 4px;
  left: 0;

  padding: 6px 4px;
  font-weight: normal;
  text-align: center;
  border-radius: 4px;
  font-size: 12px !important;

  color: var(--Blanco) !important;
  background-color: var(--AzulMP) !important;
  box-shadow: 0 5px 15px -2px var(--SombraGlobal);
}

/*=== :D  Mobile Brand Container   ==================================== */

.MobileBrandList {
  display: flex;
  flex-wrap: wrap;
}

.MobileBrandList .BrandBubble {
  width: 45vw;
  height: 45vw;
  margin: 8px auto;
}

.MobileBrandList .BrandBubble img {
  max-width: 125px;
  max-height: 125px;
}

.MobileBrandList .BrandBubble span {
  color: dimgray;
  background-color: transparent;
  font-size: var(--SizeM);
}
/*=== :D  Promociones ==================================== */

.BoxPromociones article:first-child {
  background-color: var(--Blanco);
  width: 100vw;
  overflow: hidden;
  margin-top: -3rem;
  padding-top: 3rem;
  display: grid;
  grid-template-columns: auto;
  justify-content: space-evenly;
}

.BoxPromociones article h1 {
  color: var(--AzulMP);
  font-size: 48px;
  text-align: center;

  letter-spacing: 4px;
}

.BoxPromociones article:first-child img {
  max-width: 100vw;
  margin: 3rem 0;
}

.BoxPromociones article:last-child {
  background-color: var(--Blanco);
  display: grid;
  grid-template-columns: auto;
  justify-content: space-evenly;
}
.BoxPromociones article:last-child a {
  background-color: var(--Blanco);
  max-width: 80vw;
  margin: 2rem;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 20px -8px lightgray;
}

.BoxPromociones article:last-child a h4 {
  color: dimgray;
  padding: 0.5rem 1rem;
  font-size: 20px;
}

.BoxPromociones article:last-child a p {
  color: dimgray;
  padding: 0.5rem 1rem;
  font-size: 16px;
}

.BoxPromociones article:last-child img {
  max-width: 100%;
}

/*=== :D  Vista ProductList ==================================== */

.ProductList {
  display: grid;
  grid-template-columns: 100vw;
  background-color: #eeeeee !important;
}

.ProductList .LittleProductListContainer {
  background-color: transparent;
  padding: 16px 8px 8px 8px;
}

.ProductBlock {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  width: 100%;
  height: 400px;

  border-radius: 16px;
  background-color: var(--Blanco);
  box-shadow: 0 0 20px -5px var(--SombraGlobal);
  position: relative;

  overflow: hidden;
  transform: scale(0.95);
  transition: all 0.3s;
}

.ProductBlock:hover {
  box-shadow: 0 16px 44px 0 var(--SombraGlobal);
}

.ProductDisabled {
  opacity: 0.6;
  cursor: no-drop;
}

.ProductPill {
  position: absolute;
  top: 12px;
  right: 14px;
  background-color: orangered;
  color: white;
  padding: 4px 8px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: bold;
}

.ProductBlock .ProductBlockImg {
  z-index: -1;
  transition: all 0.3s;
  max-height: 164px;
  margin-top: 8px;
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translateX(-50%);
}

.ProductBlock h4 {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 16px 8px 16px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: lighter;
  color: #777;
}

.ProductBlock small {
  background-color: rgba(255, 255, 255, 0.8);
  display: block;
  padding: 0 16px 8px 16px;
  font-size: 14px;
}

.ProductBlock ul {
  background-color: rgba(255, 255, 255, 0.8);
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px 8px 12px;
  margin: 0;
}

.ProductBlock ul li {
  background-color: var(--AzulMP);
  color: var(--Blanco);
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  margin: 4px;
  font-size: 12px;

  opacity: 0.4;
}

.ProductBlock ul .active {
  margin: 4px !important;
  opacity: 1;
}

.ProductBlock ul li:hover {
  cursor: pointer;
}

.ProductBlock ul li:last-child {
  margin: 4px !important;
}

.ProductBlock b {
  background-color: rgba(255, 255, 255, 0.9);
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 1rem 1rem 1rem;
}

.oldPrice {
  font-weight: normal;
  text-decoration: line-through !important;
  color: grey;
  font-size: var(--SizeS);
}

.newPrice {
  margin-left: 4px;
  font-size: var(--SizeM);
}

.ProductBlock span {
  background-color: rgba(255, 255, 255, 0.9);
  justify-content: flex-start;
  color: var(--VerdeMP);
  font-size: 1rem;
  margin: -12px 0 4px 16px;
  z-index: 1;
}

.ProductBlock span img {
  max-height: 30px;
  margin-right: 0.5rem;
}

.ProductBlock button {
  font-size: 20px;
  padding: 0 14px;
  height: 0;
  font-family: "GMedium";
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: all 0.3s;

  position: relative;
  bottom: -44px;
}

.ProductBlock:hover button {
  height: 44px;
  bottom: 0;
}

/*=== :D IndividualProduct ==================================== */

.IndividualProduct {
  background-color: var(--Blanco);
  width: 100vw;
  padding: 64px 8px 0 8px;
  display: grid;
  grid-template-columns: auto;
}

.IndProdHead {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.IndProdHead img {
  min-width: 100px;
  margin: 16px 0;
  max-height: 270px;
}

.DeliveryHead h3,
.IndProdHead h4 {
  font-size: var(--SizeXL);
  color: var(--NegroMP);
  margin: 8px 0;
  font-weight: lighter;
}

.DeliveryHead h1 {
  font-size: calc(var(--SizeM) * 2);
  color: var(--NegroSuave);
  font-weight: 500;
  margin-bottom: 8px;
}

.RateGold svg {
  color: gold;
  font-size: 0.8em;
}

/*.RateInitial svg{
  background-color: plum;
  color: var(--RojoMP);
  font-size: 12px;
}*/

.Delivery img {
  width: 24px;
  height: 24px;
}

.Delivery span {
  color: var(--VerdeMP);
  font-weight: bold;
}

.RateGold span,
.Delivery span {
  font-size: 11px;
  margin-left: 4px;
}

.IndProdVariations h4 {
  margin-top: 4px;
  font-size: var(--SizeM);
  color: var(--NegroSuave);
  padding-bottom: 8px;
}

.IndProdVariations .totalizador {
  display: none;
}

.IndProdVariations .IndProdVariationsBox {
  display: grid;
  grid-template-columns: calc(50% - 4px) calc(50% - 4px);
}

.IndProdVariations .IndProdVariationsBox li {
  background-color: var(--CistralBlanco);
  position: relative;

  margin: 0 0 8px 8px;
  padding: 8px 8px;

  border: 2px solid var(--AzulSuave);
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
}

.IndProdVariations .IndProdVariationsBox li:hover {
  background-color: var(--AzulSuave);
}

.Select {
  background-color: #d3f1ff !important;
  border: 2px solid var(--AzulMP) !important;
}

.IndProdVariations h5 {
  font-weight: lighter;
}

.IndProdPrice span:first-child {
  background-color: transparent;
  font-size: var(--SizeS);
  color: var(--RojoMP);
  font-family: "GMedium";
  font-weight: lighter;
  margin-right: 4px;
}

.IndProdPrice span:last-child {
  background-color: transparent;
  font-family: "GRegular";
  font-weight: bolder;
  font-size: var(--SizeXS);
}

.IndProdVariationsBox .TagVerde {
  color: var(--VerdeMP);
}

/*=== :D Acordeon ==================================== */
.Acordeon {
  max-height: 54px;
  overflow: hidden;

  transition: all 0.3s;
  margin: 8px 0 8px 0;
  border-radius: 8px;
  border: 2px solid var(--AzulOscuro);
}

.Acordeon .AcordeonHead {
  background-color: var(--AzulSuave);
  height: 54px;
  padding: 0 16px;
  color: var(--AzulMP);
  cursor: pointer;
}

.AcordeonHead h5 {
  font-size: var(--SizeM);
  color: var(--AzulMP);
}

.Acordeon .AcordeonBody {
  padding: 12px 8px;
  font-size: var(--SizeM);
}

/*.VerEntero{
  max-height: 1024px;
}

.VerEntero .RowCenter{
  box-shadow: none;
}*/

.Acordeon:hover {
  max-height: 1024px;
}

.Acordeon:hover span {
  transform: rotate(180deg);
  transition: all 0.3s;
}

/*=== :D  Pagina 404 ==================================== */

.PaginaNoEncontrada {
  background-color: var(--Blanco);
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.PaginaNoEncontrada h1 {
  font-size: 3rem;
}
.PaginaNoEncontrada p {
  font-size: 1.5rem;
}

.PaginaNoEncontrada img {
  max-width: 90%;
  max-height: 400px;
  background-color: transparent;
}

.PaginaNoEncontrada code {
  font-size: 1.5rem;
  color: grey;
}

/*=== :D  Vista ProductList > Individual Product ==================================== */

.individualOrder {
  display: none;
  background-color: green;
  margin-left: -5rem;
}

/*=== :D  Pet Carrusel para pantallas grandes ==================================== */

.MobilePetCarrusel,
.PetCarrusel {
  position: relative; /*Ayuda a que AddPet se posicione, no afecta a las burbujas de la mascota */
  z-index: 1;

  background-color: transparent;
}

.MobilePetCarrusel div,
.PetCarrusel div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PetCarruselIzq,
.PetCarruselDer {
  position: absolute;
  background-color: transparent;

  display: flex;
}

.PetCarruselIzq img,
.PetCarruselDer img {
  width: 44px;
}

.PetCarruselIzq h4,
.PetCarruselDer h4 {
  color: var(--Blanco);
  background-color: transparent;
  font-size: var(--SizeM);
}

.PetCarruselDer {
  align-items: flex-start !important;

  top: -20px;
  right: -125px;
  z-index: -1;
}

.PetCarruselDer h4 {
  width: 120px;
  padding-top: 2px;
  padding-left: 8px;
}

.PetCarruselIzq {
  align-items: flex-end !important;
  bottom: -20px;
  left: -200px;
  z-index: -1;
}

.PetCarruselIzq img {
  transform: rotate(180deg);
}

.PetCarruselIzq h4 {
  padding-bottom: 2px;
  padding-right: 8px;
}

.PetList {
  width: 100px;
  height: 100px;

  position: relative;
  background-color: transparent;
  transform: scale(1.2);
}

.Heartbeat {
  position: relative;
}

.Heartbeat::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 50%;
  animation: HeartbeatAnimation 3s infinite;
}

.conSimboloMas::after {
  content: "+";
  position: absolute;
  top: 8px;
  right: -6px;

  width: 24px;
  height: 24px;

  border-radius: 50%;
  background-color: var(--VerdeMP);
  border: 2px solid var(--Blanco);

  line-height: 25px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: var(--Blanco);
}

.PetList li {
  position: absolute;
  transform: scale(1.2);
  opacity: 0;

  width: 100px !important;
  height: 100px !important;
  background-color: var(--Blanco);
  overflow: hidden;
  border-radius: 50%;

  cursor: pointer;
}

.PetList img {
  width: 100%;
  z-index: 1;
}

.PetList li label {
  position: absolute;
  left: 0;
  bottom: -12px;

  width: 100%;
  height: 44px;

  background-color: var(--AzulSuave);
  color: var(--NegroMP);
  padding-top: 4px;
  text-align: center;
  font-size: var(--SizeS) !important;
  z-index: 2 !important;
  font-family: "GMedium";
  cursor: pointer;
}

.PetButton {
  position: absolute;

  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 20px;
  z-index: 1;

  background-color: var(--Blanco);
  opacity: 0.9;

  transition: all 0.3s;
}

.PetButton:first-child {
  left: -20px;
  padding-right: 2px;
}
.PetButton:last-child {
  right: -20px;
  padding-left: 1px;
}

.PetButton:hover {
  opacity: 1;
}

/*=== :D PetCarrusel en desktop ==================================== */

.PetCarrusel {
  background-color: transparent;
  margin: 240px 0 120px calc(50vw - 80px);
  max-width: 160px;
  transform: scale(2);
}

/*=== :D  mobile Card Bottom para compras realizacas ==================================== */

.AddPet {
  position: absolute;
  bottom: 15%;
  right: 45%;

  transform: translateX(50%);
  z-index: 1;

  width: 44px !important;
  height: 44px !important;
  border-radius: 50%;
  background-color: gold !important;
}

.MobileCardBottom {
  background-color: var(--Blanco);
  width: calc(100vw - 2rem);
  height: calc(100vh - 170px);
  overflow: hidden;
  position: fixed;
  bottom: 0;
  padding: 1rem;
  border-radius: 32px 32px 0 0;
  box-shadow: 0 -10px 20px -10px rgba(0, 0, 0, 0.2);
}

.MobileCardBottom button:first-child {
  position: absolute;
  top: 1rem;
  background-color: rgba(221, 160, 221, 0.5);
  border: none;
  width: 100vw;
  height: 48px;
  font-size: 1.3rem;
  margin: -1rem -1rem -1rem -1rem;
  animation: UpAndDown 0.5s infinite linear;
  color: dimgray;
}

/*=== :D  Perfil de la marca ==================================== */

.MobileButtonFilter {
  background-color: #1fa9e7;
  color: white;
  margin: 8px;
  padding: 0 12px 0 32px;
  line-height: 44px;
  font-size: 18px;
  position: relative;
}

.MobileButtonFilter svg {
  display: block;
  font-size: 28px;
}

.MobileButtonFilter span {
  width: 28px;
  display: block;
  line-height: 28px;

  border-radius: 50%;
  background-color: gold;
  color: #222;
  text-align: center;
  font-weight: bold;

  transform: scale(0.8);

  position: absolute;
  top: 8px;
  right: 4px;
}

.FilterModalBox {
  background-color: white;
  padding: 8px 8px 8px 8px;

  position: fixed;
  top: 63px;
  height: calc(100vh - 63px);
  width: 100vw;
  z-index: 100;

  overflow: scroll;
}

.FilterModalBox .BtnAplicar {
  position: sticky;
  top: 0;
  background-color: #01c167;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  line-height: 44px;
  margin-bottom: 8px;
}

.CustomFilters li {
  background-color: #f3f3f3;
  margin-bottom: 1rem;
  align-items: flex-start;

  border-radius: 8px;
}

.CustomFilters li a {
  padding: 8px 16px 0 8px;
}

.CustomFilters li h4 {
  padding: 1rem 0 8px 1rem;
}

.CustomFilters li span {
  margin-left: 0.5rem;
}

.CustomFilters li svg {
  transition: all 0.3s;
  width: 32px;
  height: 32px;
  stroke: dimgray;
  fill: dimgray;

  margin: 0.5rem 0.5rem 0 0.5rem;
  transition: all 0s !important;
}

.CustomFilters li .RowCenter:last-child {
  background-color: transparent;
  min-width: 145px;
  padding: 0 0 0.5rem 0;
  justify-content: space-around;
}

.CustomFilters li .RowCenter:last-child button {
  border: none;
  background-color: transparent;
  min-height: 70px;
  min-width: 64px;
}

.CustomFilters li .RowCenter:last-child button p {
  font-size: 80%;
  color: dimgray;
  font-weight: bold;
}

.CustomFilters .medicado button {
  font-size: 2rem;
  margin-left: -24px;
}

.CustomFilters .Especies {
  max-width: 50%;
}

.CustomFilters .Edades {
  max-width: 75%;
}

.ListaMarcas button {
  display: block;
  background-color: var(--Blanco) !important;
  border: none;
  width: 75px;
  height: 75px;
  margin: 8px 0;
  border-radius: 50%;
  overflow: hidden;
  transform: scale(0.9);
}

.ListaMarcas button img {
  max-width: 64px;
  max-height: 64px;
}

.CustomFilters li .active svg {
  stroke: var(--AzulMP) !important;
  fill: var(--AzulMP) !important;
  color: var(--AzulMP) !important;
}

.CustomFilters li .active p {
  color: var(--AzulMP) !important;
}

.FilterAcordeon {
  max-height: 175px;
  overflow: hidden;
  transition: all 0.3s;
}

.FilterAcordeon:hover {
  max-height: calc(100vh * 10);
}

.FilterAcordeon .FilterBrandBubble {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.FilterAcordeon .FilterBrandBubble button {
  width: 100px;
  height: 100px;
  margin: 4px 0;
  background-color: var(--Blanco);
  border-radius: 50%;
  border: 2px solid #ddd;
  overflow: hidden;
}

.FilterAcordeon .FilterBrandBubble .active {
  border: 2px solid var(--AzulMP);
}

.FilterAcordeon .FilterBrandBubble img {
  max-width: 80px;
}

/*=== :D FiltersTopNav =================================== */
.FiltersTopNav {
  box-shadow: 0 5px 5px -5px var(--SombraGlobal);
  background-color: var(--Blanco);
  max-height: 44px;
  overflow: hidden;
  transition: all 0.3s;
}

.ActiveFiltersTopNav {
  animation-delay: 0.3s;
  max-height: 3000px;
}

.FiltersTopNav .CustomFilters {
  margin: 8px;
}
.FiltersHead {
  height: 44px;
  padding: 0 8px;
  background-color: var(--AzulSuave);
  font-size: var(--SizeS);
  position: relative;
}

.FiltersHead strong {
  background-color: var(--AzulMP);
  padding: 4px 12px;
  border-radius: 16px;
  color: var(--Blanco);
}

.CloseFiltersTopNav {
  background-color: transparent;
  display: block;
  padding: 8px;
  text-align: center;
  color: var(--AzulMP);
  font-size: var(--SizeXL);
}

/*=== :D  Lista de Horarios =================================== */

.PurchaseOption {
  background-color: transparent;
  width: 100vw;
  min-height: 100vh;
  padding: 64px 0 0 0 !important;
}

.PurchaseOption h2 {
  text-align: left;
  margin: 16px 0 16px 8px;
  padding: 0;
  font-size: var(--SizeXL);
  color: var(--NegroMP);
}

.PurchaseOption .btn-blue {
  max-width: calc(100% - 16px);
}

.ListaHorarios .CajaDeDias {
  display: flex;
  justify-content: space-between;
}

.ListaHorarios .CajaDeDias ol {
  display: flex;
  overflow-y: scroll;
  background-color: var(--Blanco);
}

.OmiCalendar {
  border-radius: 8px;
  overflow: hidden;
  margin: 8px 0;
  box-shadow: 0 0 20px -10px var(--SombraGlobal);
}

.infoEnvio {
  background-color: var(--VerdeSuave);
  border: 2px solid var(--VerdeMP);
  margin: 8px;
  padding: 8px 16px;
  color: var(--VerdeOscuro);
  border-radius: 8px;
}

.infoEnvio img {
  max-height: 32px;
}

.infoEnvio h4 {
  text-transform: uppercase;
  display: inline;
}

.ListaHorarios ol::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ListaHorarios .CajaDeDias ol li {
  border-top: 5px solid var(--Blanco);
  justify-content: center;
  flex-flow: column;
  padding: 8px 18px;
  color: var(--NegroSuave);
  cursor: pointer;
}

.ListaHorarios .CajaDeDias ol li:hover {
  background-color: var(--Blanco);
  color: var(--VerdeMP);
}

.ListaHorarios .CajaDeDias .DiaElegido {
  border-top: 5px solid var(--VerdeMP);
  color: var(--VerdeMP);
}

.ListaHorarios .CajaDeDias ol li h3 {
  font-size: var(--SizeL);
}
.ListaHorarios .CajaDeDias ol li span {
  font-size: var(--SizeXL);
}

.ListaHorarios ul li {
  background-color: var(--Blanco);

  width: 100%;
  padding: 8px;
}

.ListaHorarios ul li div {
  background-color: var(--Blanco);
  margin-top: 8px;

  border-radius: 8px;
  position: relative;
}

.ListaHorarios ul li div span {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  color: var(--AzulOscuro);
  font-size: 14px;
}

.ListaHorarios ul li div label {
  border-radius: 8px;
  background-color: var(--AzulSuave);
  width: 100%;
  height: 54px;
  line-height: 54px;
  color: var(--AzulMP);
  transition: all 0.3s;

  padding-left: 48px !important;
  position: relative;
}

.ListaHorarios ul li div label:active {
  transform: scale(0.95);
}

.ListaHorarios ul li div input[type="radio"]:checked,
.ListaHorarios ul li div input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.ListaHorarios ul li div input[type="radio"]:checked + label,
.ListaHorarios ul li div input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  display: inline-block;
}
.ListaHorarios ul li div input[type="radio"]:checked + label:before,
.ListaHorarios ul li div input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 16px;
  top: 18px;
  width: 18px;
  height: 18px;
  border: 1px solid var(--VerdeMP);
  background-color: white;
  border-radius: 100%;
}
.ListaHorarios ul li div input[type="radio"]:checked + label:after,
.ListaHorarios ul li div input[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  background: var(--VerdeMP);
  position: absolute;
  top: 20px;
  left: 18px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.ListaHorarios ul li div input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.ListaHorarios ul li div input[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.PurchaseOption .BoxCarrito .ItemCarrito {
  margin: 24px 8px;
}

/*=== :D  item Carrito ==================================== */

.ItemCarrito {
  position: relative;

  margin: 1rem;
  margin-top: 1.5rem;
  padding: 8px 0.5rem 8px 1rem;

  border-radius: 0;
  border-bottom: 1px solid var(--ColorFooter);
  background-color: var(--Blanco);

  display: grid;
  grid-template-columns: 44px auto;
  align-items: center;
}

.ItemCarrito img {
  max-width: 40px;
  max-height: 70px;
  margin-left: -0.5rem;
}

.ItemCarrito h4,
.ItemCarrito b {
  font-size: 90%;
}

.ItemCarrito h4 {
  font-weight: 500;
  padding-right: 1rem;
}

.ItemCarrito p {
  text-align: left !important;

  padding: 4px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 8px !important;

  color: var(--AzulMP);
  background-color: var(--AzulSuave);
}

.ItemCarrito .ItemCarritoColum {
  display: flex;
  justify-content: space-between;
  flex-flow: column;

  background-color: transparent;
  min-height: 70px;
}

.BotonesMasomenos {
  background-color: var(--Blanco);
  height: 32px;
  width: 108px;

  display: grid;
  grid-template-columns: 32px 44px 32px;

  border-radius: 4px;
  overflow: hidden;
}

.BotonesMasomenos .BotonMenos,
.BotonesMasomenos .BotonMas,
.BotonesMasomenos .BotonLabel {
  line-height: 32px;
  text-align: center !important;
}

.BotonesMasomenos .BotonMenos,
.BotonesMasomenos .BotonMas {
  background-color: var(--AzulMP) !important;
  color: var(--Blanco);
  cursor: pointer;
}

.BotonesMasomenos .BotonLabel {
  box-shadow: 0 2px 0 var(--AzulMP) inset, 0 -2px 0 var(--AzulMP) inset;

  padding: 0;
  border-radius: 0;
  display: inline-block;
  margin-top: 0 !important;

  color: var(--AzulMP);
  background-color: var(--Blanco);
}

.BotonesMasomenos .BotonMenos:active {
  background-color: var(--AzulOscuro);
}
.BotonesMasomenos .BotonMas:active {
  background-color: var(--AzulOscuro);
}

.CarritoDeleteItem {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;

  position: absolute;
  left: -8px;
  top: -12px;

  border-radius: 16px;
  box-shadow: 0 0 10px -5px var(--SombraGlobal);
  background-color: var(--Blanco);

  transition: all 0.5s;
}

.CarritoDeleteItem svg {
  color: var(--RojoMP);
  font-size: 14px;
}

.CarritoDeleteItem:active {
  transform: scale(0.8);
}

/*=== :D  Payment Method  ==================================== */

.PaymentMethod {
  display: grid;
  grid-template-columns: auto;
  padding: 64px 8px 70px 8px !important;
}

.PaymentMethod h2 {
  padding: 8px 0;
  font-size: var(--SizeXL);
}

.PaymentMethod .Card h3 {
  margin-bottom: 12px;
  font-size: var(--SizeM);
}

.PaymentMethod .Acordeon {
  max-height: 200vh;
  padding: 16px;
  max-width: 450px;
  margin: auto;
  border: none;
}

.CheckoutPayment h3 {
  margin-top: 4px;
  margin-bottom: -4px;
}

.CheckoutPayment svg {
  width: 32px;
  height: 32px;
}

.CheckoutPayment fieldset {
  margin-top: 8px;
  border-radius: 8px;
  background-color: #f8f8f8;
  border: 1px solid var(--GrisClaro);
  padding: 8px;

  cursor: pointer;
  overflow: hidden;
}

.CheckoutPayment fieldset .RowCenter {
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
}

.CheckoutPayment fieldset svg {
  margin-right: 8px;
  color: transparent;
  font-size: 0;
  transition: all 0.3s;
}

.CheckoutPayment fieldset input {
  width: 64px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid var(--GrisClaro);
  padding: 12px 0;
}

.CheckoutPayment fieldset .css-yk16xz-control {
  margin-top: 16px;
  border-radius: 8px !important;
}

.CheckoutPayment fieldset .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.CheckoutPayment fieldset .css-g1d714-ValueContainer {
  background-color: transparent;
}

.CheckoutPayment fieldset .css-1uccc91-singleValue {
  font-size: 14px;
  text-align: center !important;
  background-color: transparent;
  display: block;
  width: 100%;
  margin-left: -4px;
}

.CheckoutPayment .active {
  background-color: var(--AzulSuave);
  border-color: var(--AzulMP);
}

.CheckoutPayment .active svg {
  font-size: 20px;
  color: var(--AzulMP);
}

.CheckoutPayment .MobileNuevaTC,
.CheckoutPayment .DeskNuevaTC {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  padding: 12px;
  margin-top: 16px;
  border-radius: 8px;
  background-color: #f8f8f8;
  border: 1px solid var(--GrisClaro);
  font-weight: bold;
  color: var(--NegroMP);
  font-size: 16px;
}

.CheckoutPayment .MobileNuevaTC svg,
.CheckoutPayment .DeskNuevaTC svg {
  font-size: 20px;
  margin-right: 8px;
  transition: all 0.3s;
}

.CheckoutPayment .ActiveNuevaTC {
  background-color: var(--AzulSuave);
  border: 1px solid var(--AzulMP);
  color: var(--AzulMP);
}

.CheckoutPayment .ActiveNuevaTC svg {
  transform: rotate(90deg);
  color: var(--AzulMP);
}

.PurchaseSummary li {
  background-color: whitesmoke;
  height: var(--AltoEstandar);
  padding: 0 12px;
  margin-bottom: 8px;
  border-radius: 8px;
}

.PurchaseSummaryTotal {
  margin-top: 8px;
  padding: 0 12px;
  height: var(--AltoEstandar);
}

.PurchaseSummary .backBlue {
  background: var(--AzulSuave) !important;
}

.ChangeAddress div {
  background-color: whitesmoke;
  height: var(--AltoEstandar);
  padding: 0 12px;
  margin-bottom: 8px;
  border-radius: 8px;
}

.ChangeAddress b {
  font-size: var(--SizeM);
  color: var(--NegroSuave);
}

.CheckoutClarification textarea {
  width: 100%;
  resize: none;
  min-height: 64px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--AzulMP);
}

/*=== :D  Animaccion en FINALIZAR COMPRA  ==================================== */

.PaymentMethod article:last-child .btn-CompraVerde:active {
  background-color: var(--VerdeOscuro);
  box-shadow: 0 5px 20px -20px var(--VerdeMP);
}

.PaymentMethod article:last-child .btn-ActiveLoading {
  box-shadow: 0 5px 20px -20px transparent, 0 0 0 inset var(--VerdeOscuro);
}

.btn-ActiveLoading svg {
  animation: Giro360 0.5s infinite linear;
  font-size: var(--SizeXL);
}

.SuccesPay {
  animation-name: succesPay;
  animation-duration: 3 s;
}

.DeclinedPay {
  background-color: var(--RojoMP);
  animation-name: declinedPay;
  animation-duration: 3s;
}

.SuccesPayBox p {
  color: var(--Blanco);
  max-width: 300px;
  text-align: center;
  margin-top: 1rem;
}

.SuccesPayBox ol {
  background-color: plum;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
}

.SuccesPayBox ol li {
  margin: 0.5rem 0;
  background-color: rebeccapurple;
  padding: 0.5rem;

  display: grid;
  grid-template-columns: 64px auto 128px;
}

/*=== :D  Credit Card  ==================================== */

.CreditCard {
  position: relative;
  background-color: transparent;
  width: 100%;
}

.CreditCardFront,
.CreditCardEnd {
  background-repeat: no-repeat;
  width: 100% !important;
  height: 230px;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.5s;

  padding: 0 20px;
  margin: 0 -12px 0 0 !important;
}

.CreditCardFront {
  background-image: url("../../IMG/picho_card_front.png");
  background-size: contain;
  background-position: center;

  transform: perspective(700px) rotateY(0deg);
}

.CreditCardEnd {
  background-image: url("../../IMG/picho_card_end.png");
  background-position: center;
  transform: perspective(700px) rotateY(-180deg);
  background-size: contain;
  background-color: plum;
  position: absolute;
  top: 0;
  left: 0;
}

.FrontActive {
  transform: perspective(700px) rotateY(180deg);
}

.EndActive {
  transform: perspective(700px) rotateY(0deg) !important;
}

.CreditCard ol {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 16px;
}

.CreditCard ol li {
  margin: 4px 0 !important;
  width: 100%;
  text-align: right !important;
}

.CreditCard .CreditCardFront li:last-child {
  background-color: transparent;
  text-align: left !important;
  text-transform: uppercase;
}

.CreditCard ol li span {
  font-family: monospace, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--Blanco);
  padding: 8px;
  margin-bottom: 8px !important;
}

.CreditCard .CreditCardEnd {
  justify-content: start;
  background-color: transparent;
}

.CreditCard .CreditCardEnd li {
  padding-top: 84px;
  background-color: transparent;
  color: dimgray !important;
  text-align: left !important;
}

.FormCreditCard {
  padding: 8px;
  margin-top: 8px;
  width: 100%;
  border-radius: 8px;

  background-color: var(--Blanco);
  box-shadow: 0 0 20px -10px var(--SombraGlobal);
}

.FormCreditCard fieldset {
  background-color: transparent;
  margin: 8px 0;
  border: none;
  display: flex;
  flex-direction: column;
}

.FormCreditCard .RowCenter fieldset {
  max-width: 48%;
}

.FormCreditCard fieldset label {
  background-color: transparent;
  font-size: var(--SizeM);
  color: var(--NegroMP);
  margin: 4px 0 8px 0 !important;
}

.FormCreditCard input {
  height: 44px;
  width: 100% !important;

  border: 1px solid var(--GrisClaro);
  border-radius: 8px;

  font-size: var(--SizeM);
  text-align: center;
}

.PoweredBy {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  color: var(--NegroSuave);

  grid-column: 1 / 3;
}

.PoweredBy img {
  max-width: 50px;
  margin-left: 8px;
}

/*=== :D  BG Colores  ==================================== */

.Bg-Alert-Verde {
  background-color: var(--Blanco) !important;
}

.Bg-Rojo {
  background-color: var(--RojoMP) !important;
}

.AlertDanger {
  background-color: var(--RojoMP);
  color: var(--Blanco);
  box-shadow: 0 0 20px var(--SombraGlobal);
}

.AlertDanger svg {
  margin-right: 0.5rem;
}
.AlertDanger h4 {
  margin-bottom: -1rem;
}

.AlertDanger p {
  font-size: 90%;
}

/*=== :D  Compra Realizada  ==================================== */

.PurchaseMade {
  padding: 32px 8px;
}

.PurchaseMadeAnimation {
  background-color: transparent;
  animation: PurchaseMadeAnimation 1.5s;
  transition: all 0.3s;
  height: 200px;
  justify-content: center;
}

.IconPurchaseMade {
  color: var(--VerdeMP);
  font-size: 128px;
}

.PurchaseMade .Card {
  padding: 8px;
}

.PurchaseMade h4 {
  font-size: 16px;
  padding-left: 4px;
}

.PurchaseMade ul li {
  background-color: #e7e7e7;
  margin: 8px 0;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.PurchaseMade ul li svg {
  font-size: 16px;
  width: 18px !important;
  margin-right: 8px;

  color: var(--NegroMP);
}

.PurchaseMade ul li b {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--NegroMP);
}

.PurchaseMade .DataProducts li {
  display: grid;
  grid-template-columns: 44px auto 64px;
  align-items: center;

  margin-top: 8px;
  padding-bottom: 8px;

  border-bottom: 2px solid #e7e7e7;
}

.PurchaseMade .DataProducts li img {
  max-height: 64px;
  margin: 0 auto;
}

.PurchaseMade .DataProducts li p {
  font-size: var(--SizeM);
  padding: 0 8px;
}

.PurchaseMade .DataProducts li b {
  text-align: center;
}

.PurchaseMade .Botonera {
  width: 100vw;
  margin: 0 0 0 -8px;
  padding: 8px;

  position: fixed;
  bottom: 0;

  background-color: white;
  box-shadow: 0 0 20px -10px var(--SombraGlobal);
}

/*=== :D  Mi Cuenta ==================================== */

.MiCuenta {
  max-width: 100%;
  margin-top: 16px;
}

.DrawerBox .MiCuenta {
  margin-top: 0;
  padding-top: 20px;
}

.MiCuenta ul {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  justify-content: center;
  align-items: center;
}

.Tile {
  background-color: var(--Blanco);
  color: var(--AzulMP);

  box-shadow: 10px 10px 20px -15px var(--SombraGlobal);
  border-radius: 16px;

  min-height: 70px;
  min-width: 70px;
  transition: all 0.3s;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-size: 11px;
  font-family: "GMedium";

  margin: 8px;
}

.Tile svg {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.MiCuenta ul .active {
  background-image: linear-gradient(
    to right bottom,
    rgb(56, 205, 255),
    var(--AzulMP)
  );
  color: var(--Blanco);
}

.MiCuenta .Relleno {
  opacity: 0;
}

.MiCuenta ul li svg {
  transition: all 0s !important;
}

.MiCuenta .Card p {
  margin-top: 1.5rem;
}

.MiCuenta .Acordeon {
  margin-bottom: 0.5rem;
  background-color: var(--Blanco);
  border-radius: 16px;
}

.MiCuenta .Acordeon h3 {
  font-size: 1rem;
}

.MiCuenta .Acordeon button {
  border: none;
  padding: 1rem;
  margin: -1rem;
  background-color: transparent;
  width: calc(100% + 2rem);
  font-size: 1.1rem;
}

.MiCuentaBox {
  padding: 0 8px;
}

.MiCuentaBox h4 {
  background-color: transparent;
  margin: 8px 0 16px 0;
  font-size: var(--SizeL);
}

/*=== :D Mascota en UserAccaunt ==================================== */

.MascotasTile {
  width: 50px;
  height: 50px;
  margin: -10px 0 0 0 !important;
  stroke: var(--AzulMP);
  fill: var(--AzulMP);
}

.MascotasLabel {
  margin-top: -6px;

  font-size: 12px;
  font-family: "GMedium";
}

.active .MascotasTile {
  stroke: var(--Blanco);
  fill: var(--Blanco);
}

.LittlePetItem {
  background-color: var(--Blanco);
  display: grid;
  grid-template-columns: 55px auto;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px -5px var(--SombraGlobal);
  align-items: center;
}

.LittlePetItemImg {
  width: 70px;
  height: 70px;
  margin: 0 5px;
  background-color: var(--Blanco);
  border-radius: 50%;
  background-color: transparent;
}

.LittlePetItem .LittlePetItemBox {
  background-color: transparent;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
}

.LittlePetItem .LittlePetItemBox button {
  background-color: var(--AzulMP);
  border: none;
  padding: 6px;
  border-radius: 4px;
  color: var(--Blanco);
  max-width: 100px;
}

/*=== :D  Mini Order, para CardBottom y para Mi Cuenta ==================================== */

.ConSombra {
  box-shadow: 0 0 20px -10px var(--SombraGlobal);
}

.BG-White {
  background-color: var(--Blanco) !important;
}

.LittleOrder {
  padding: 16px 8px 8px 8px;
  border-radius: 16px;
  background-color: var(--Blanco);
  margin-bottom: 1rem;
  animation-name: aparece;
  animation-duration: 1s;
}

.LittleOrder .OrderStepBox {
  background-color: white;
  margin-left: 5%;
}

.LittleOrderHead span,
.LittleOrderHead h5 {
  color: var(--NegroMP);
}

.LittleOrderHead span {
  font-family: "GMedium";
}

.LittleOrderItem {
  background-color: #e7f7ff;
  padding: 8px;
  margin-top: 8px;
  border-radius: 8px;
}
.LittleOrderItem svg {
  color: var(--AzulMP);
}

.LittleOrderItem span {
  font-size: 14px;
}

.LittleOrder .Botonera button {
  width: calc(50% - 4px);

  margin-top: 8px;
  padding: 8px;
  border-radius: 8px;
  font-size: 16px;
  border: none;
  font-family: "GMedium";
}

.LittleOrder .Botonera button svg {
  margin-right: 16px;
  font-size: 20px;
}

.LittleOrder .FavoritProduct {
  box-shadow: none;
  margin: 10px 0 0 0;
  background-color: var(--AzulSuave);
}

.Botonera .Btn-Cancel {
  background-color: #ffe8e3;
  color: var(--RojoMP);
}

.Botonera .Btn-Cancel svg {
  transform: scale(0);
  margin-right: -16px !important;
}

.Botonera .Btn-Help {
  background-color: whitesmoke;
  color: deepskyblue;
}

.Botonera .Btn-Fav {
  background-color: #fff3ae;
  color: goldenrod;
}

.Botonera .Btn-Succes {
  background-color: rgba(144, 238, 144, 0.5);
  color: green;
}

/*=== :D  Alertas ==================================== */

.Alert {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  /*align-items: center;*/

  padding: 8px;
  margin: 8px 0;

  border-radius: 8px;
  background-color: #ddd;
  border: 1px solid #555;
  cursor: pointer;
}

.Alert h5 {
  text-align: left;
  width: 100%;
}

.Alert p {
  margin: 4px 0 0 8px;

  font-size: 14px;
  text-align: left;
  width: 100%;
}

.Alert button {
  width: calc(50% - 4px);
  border: none;
  padding: 8px;
  margin-top: 12px;
  border-radius: 4px;
  background-color: #555;
  color: var(--Blanco);
}

.Alert .Alternative {
  padding: 8px;
  margin-top: 12px;
  border-radius: 4px;
  text-align: center;
  width: calc(50% - 4px);
}

/*========================*/
.Alert-Info {
  background-color: #e7f7ff;
  border-color: var(--AzulMP);
}

.Alert-Info h5,
.Alert-Info p {
  color: var(--AzulMP);
}

.Alert-Info button {
  background-color: var(--AzulMP);
}

/*========================*/

.Alert-Danger {
  background-color: #ffe4e0;
  border-color: var(--RojoMP);
  cursor: pointer;
}
.Alert-Danger h5,
.Alert-Danger p {
  color: var(--RojoMP);
}

.Alert-Danger button {
  background-color: var(--RojoMP);
}

/*========================*/

.Alert-Warning {
  background-color: #fff2a9;
  border-color: goldenrod;
}
.Alert-Warning h5,
.Alert-Warning p {
  color: goldenrod;
}

.Alert-Warning button {
  background-color: goldenrod;
}

/*========================*/

.Alert-Success {
  background-color: #e1fff1;
  border-color: var(--VerdeOscuro);
}
.Alert-Success h5,
.Alert-Success p {
  color: var(--VerdeOscuro);
}

.Alert-Success button {
  background-color: var(--VerdeOscuro);
}

/*=== :D CardBottom!! ==================================== */

.CardBottom {
  background-color: var(--Blanco);
  position: fixed;
  bottom: 0;

  width: 100vw;
  height: calc(100vh - 190px);
  overflow-y: scroll;

  padding: 0;

  transform: translateY(calc(100vh - 270px));
  box-shadow: 0 0 20px -5px var(--SombraGlobal);

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: all 0.3s;

  z-index: 1;
}

.btn-soltar {
  border: 2px solid var(--RojoMP);
  padding: 16px;
  font-size: var(--SizeL);
  color: var(--RojoMP);
  background-color: var(--RojoSuave);
  border-radius: 8px;
}

.CardBottom h2 {
  background-color: transparent;
  text-align: center;
}

.CardBottom ol {
  background-color: transparent;
  height: auto;
  overflow-y: scroll;
  padding-top: 16px;
}

.CardBottom ol li:last-child {
  /* controla la imagen de logo del ultimo item de la lista */
  background-color: transparent;
  display: flex;
  justify-content: center;
}
.CardBottom ol li img {
  max-width: 64px;
}

.CardBottom .LittleOrder {
  border: 2px solid #e7f7ff;
  margin-bottom: 16px;
}

.CardBottom .CardBottomHead {
  cursor: pointer;
  background-color: transparent;
  height: 54px;
  margin: 0 0 0 0;
}

.CardBottom .CardBottomHead h2 {
  cursor: pointer;
  margin-top: 4px;
  font-size: var(--SizeM);
  color: var(--NegroSuave);
}

.SingleHeadArrowUp {
  margin: 16px 0 -4px 0;
  font-size: 16px;
  background-color: transparent;
  color: var(--NegroMP);
}

.OrderStepBox {
  max-width: 90%;
  margin: 8px;
  background-color: transparent;

  box-shadow: inset var(--Blanco) -40px 0 0, inset var(--Blanco) 40px 0 0,
    inset var(--Blanco) 0 12px 0, inset #eaf8ff 0 15px 0;
}

.OrderStep svg {
  font-size: 20px;
  background-color: var(--Blanco);
  color: #e7f7ff;
}

.OrderStepBox .active svg {
  color: var(--AzulMP);
}

.OrderStep span {
  color: var(--NegroSuave);
  padding-top: 4px;
  font-size: var(--SizeS);
  font-family: "GMedium";
}

.OrderStep:nth-child(2) {
  margin-right: 10px;
}

/*=== :D  PurchaseMade dentro de Cardbottom ==================================== */

.CardBottom .Canvas {
  padding: 0;
  margin: 0;
}

.CardBottom .PurchaseMade {
  background-color: var(--Blanco) !important;
  padding: 16px 4px;
  margin: -8px 0;
}

.CardBottom .PurchaseMade .Card {
  box-shadow: none;
}

.CardBottom .PurchaseMade h2,
.CardBottom .PurchaseMade .Botonera {
  display: none;
}

.CardBottom .SecondaryBox {
  margin: -8px -8px;
}

/*=== :D  Slide dentro de Cardbottom ==================================== */

.CardBottom .PrimaryBox,
.CardBottom .SecondaryBox {
  padding: 8px;
  height: auto;
}

/*=== :D  PetBanner ==================================== */

.PetBanner {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;

  position: relative;
  background-color: transparent;
  box-shadow: 0 -380px 0 white inset;
  padding: 128px 0 44px 0;

  overflow: hidden;
}

.PetBanner::before {
  background-color: white;

  position: absolute;
  content: "";
  top: 120px;
  left: 0;

  width: 100%;
  height: 250px;

  border-radius: 50%;
  transform: scale(1.8);
}

.PetBanner h3 {
  color: var(--NegroMP);
  grid-column: 1;
  z-index: 1;
  width: 100%;
  background-color: transparent;
  text-align: center;
  font-size: var(--SizeXL);
  margin-top: -44px;
  margin-bottom: 8px;
}

.PetBanner div {
  display: flex;
  flex-flow: column;
  align-items: center;

  background-color: transparent;
  z-index: 1;
}

.PetBanner div svg,
.PetBanner div img {
  max-width: 250px;
  height: auto;
}

.PetBanner .Btn-Action {
  justify-content: space-between;
}

.PetBanner .Btn-Action svg {
  margin: 0 0 0 32px;
}

/*=== :D  Queries para Responsive ==================================== */

.DrawerGeneric {
  background-color: #fafafa;
  position: fixed;
  top: 0;
  right: 0;

  min-width: 320px;
  max-width: 375px;
  height: 100vh;

  box-shadow: 0 0 20px transparent;
  transform: translateX(120vw);
  transition: all 0.3s;
  z-index: 1600;
}

.CloseDrawer {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  position: absolute;
  top: 0;
  left: calc(-100vw + 375px);
  height: 100vh;
  width: calc(100vw - 375px);

  animation-name: fadeIn;
  animation-duration: 1s;
  display: none;
}

.DrawerBox {
  padding-top: 0;
  height: calc(100vh - 54px);
  overflow-y: scroll;
}

.DrawerGeneric nav {
  background-color: var(--AzulMP);
  height: 64px;
  padding: 0 16px;
}

.DrawerGeneric nav svg {
  color: var(--Blanco);
  font-size: 24px;
  cursor: pointer;
}

.DrawerBox {
  width: 375px;
}

/*=== :D  Veterinarias ==================================== */
.VeterinaryCard {
  padding: 16px 4px;
  min-width: 300px;

  border-radius: 8px;
}

.VeterinaryCard img {
  max-height: 64px;
  max-width: 64px;
}

.VeterinaryCard .RowCenter:first-child {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 4px;
}

.VeterinaryCardBody h4 {
  font-size: var(--SizeL);
}

.VeterinaryCardBody p {
  margin: 2px 0;
  font-size: var(--SizeM);
}

.VeterinaryCard .RowCenter:last-child .Btn-Action {
  background-color: var(--VerdeMP);
  color: var(--Blanco);
  width: 100%;
  margin: 8px 4px -8px 4px;
  padding: 0.5rem;

  border-radius: 4px;
  border: none;
  font-size: 80%;
  text-align: center;
  cursor: pointer;
}

.ListaPet {
  /* Canvas, seccion de Vets */
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 350px 350px 350px;
  column-gap: 32px;
}

/*=== :D  Queries para Responsive ==================================== */
.SeguroBlock {
  display: flex;
  flex-flow: column;
  align-items: center;

  animation: CardFlipPresentation 1s;
}

.SeguroBlock img {
  max-width: 100px;
}

.SeguroBlock h4 {
  margin: 8px 0;
}

.SeguroBlock p {
  text-align: center;
}

.SelectPet p {
  font-size: var(--SizeM);
}

.SelectPlan {
  margin-top: 8px;
}

.SelectPlanOption {
  background-image: linear-gradient(to right bottom, white, whitesmoke);
  margin: 0 4px;

  padding: 12px 8px;
  font-family: "GMedium";
  color: lightslategray;

  text-align: center;
  border-radius: 8px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  transition: all 0.3s;
}

.SelectPlanOption:active {
  transform: scale(0.8);
}

.SelectPlan .active {
  background-image: linear-gradient(
    to right bottom,
    rgb(56, 205, 255),
    var(--AzulMP)
  );
  color: var(--Blanco);
}

.SelectPetImage {
  background-color: plum;
  width: 44px;
  height: 44px;
  margin: 8px;
}

.ProtectedPetList b {
  margin-right: 8px;
}

/*=== :D  Queries para Responsive ==================================== */
.NewPet {
  margin-top: 54px;
  background-color: transparent;
  min-width: 100vw;
}

.NewPet h2 {
  background-color: transparent;
  padding: 2rem 0.5rem 0 1rem;
  text-align: center;
  font-size: 150%;
}

.NewPet h2::first-letter {
  text-transform: capitalize;
}

.NewPet .PetForm {
  width: 100%;
  background-color: transparent;
}

.NewPet .PetForm fieldset {
  display: flex;
  flex-flow: column;
  align-items: center;

  background-color: transparent;
  border: none;
  padding: 8px;

  position: relative;
  width: 100%;
}
.NewPet .PetForm .FormDisabled,
.NewPet .PetForm .FormBack,
.NewPet .PetForm .FormNext {
  background-color: #fafafa;
  color: dimgray;
  border: none;
  padding: 0.5rem;

  width: calc(50% - 1rem);
  height: 44px;

  position: fixed;
  bottom: 16px;

  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;

  transition: all 0.3s;
}

.NewPet .PetForm .FormDisabled {
  right: 0.5rem;
  background-color: #ccc;
  color: grey;
}
.NewPet .PetForm .FormBack {
  left: 0.5rem;
  color: var(--AzulMP);
}
.NewPet .PetForm .FormNext {
  right: 0.5rem;
  background-color: var(--AzulMP);
  color: var(--Blanco);
}

.NewPet .PetForm .FormBack:active {
  transform: scale(0.9);
}
.NewPet .PetForm .FormNext:active {
  transform: scale(0.9);
}

.NewPet .PetForm label {
  background-color: transparent;
  text-align: center;
  font-weight: 500;
  margin: 1rem 0 0 0;
  text-align: left;
  font-size: 90%;
}

.NewPet label::first-letter {
  text-transform: capitalize;
}

.NewPet input[type="radio"] {
  display: none;
}

.NewPet .PetForm input[type="text"] {
  background-color: var(--Blanco);
  border: none;
  border-bottom: 2px solid var(--AzulMP);

  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
  padding: 1rem;
  font-size: 18px;
  font-weight: 500;
  color: dimgray;

  text-transform: capitalize;
}

.NewPet #especie button,
.NewPet #sex button {
  margin: 8px;
  width: 130px;
  height: 130px;

  background-color: var(--Blanco);
  border: 3px solid dimgray;
  border-radius: 16px;
  margin-top: 2rem;
  margin-bottom: -2rem;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.NewPet #especie button svg,
.NewPet #sex button svg {
  stroke: dimgray;
  fill: dimgray;
  transition: all 0.3s !important;
}

.NewPet #especie .active,
.NewPet #sex .active {
  border: 3px solid var(--AzulMP);
  color: var(--AzulMP);
}

.NewPet #especie .active svg,
.NewPet #sex .active svg {
  stroke: var(--AzulMP);
  fill: var(--AzulMP);
  color: var(--AzulMP);
  transition: all 0.3s !important;
}

.NewPet #especie label,
.NewPet #sex label {
  font-size: 20px;
  margin-top: 12px;
}

.fem {
  border: 3px solid deeppink !important;
  color: deeppink !important;
}

.fem svg {
  stroke: deeppink !important;
  fill: deeppink !important;
}

#calendar {
  background-color: transparent;
  min-height: 60vh;
}

#calendar .DayPicker {
  background-color: transparent;
  width: 50vh;
  height: 50vh;

  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%) scale(1.2);
}

#calendar select {
  background-color: var(--Blanco);
  width: calc(50% - 8px);
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0 4px;
  appearance: none;
}

#calendar select option {
  background-color: whitesmoke;
}

/* ========== */

#calendar select {
  background-image: linear-gradient(45deg, transparent 50%, grey 50%),
    linear-gradient(135deg, grey 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) calc(1em + 2px),
    calc(100% - 10px) calc(1em + 2px), calc(100% - 2.2em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

#calendar select:focus {
  background-image: linear-gradient(45deg, var(--AzulMP) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, var(--AzulMP) 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 10px) 1em, calc(100% - 15px) 1em,
    calc(100% - 2em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: var(--AzulMP);
  outline: 0;
}

.DayPicker-Day--selected {
  background-color: var(--AzulMP) !important;
}

/* ========== */

.FormCheck {
  background-color: var(--Blanco) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 12px 0 !important;
  padding: 10px 16px;
  border: 2px solid dimgray;
  border-radius: 8px;

  color: dimgray;

  transition: all 0.3s;
}

.FormCheck svg {
  color: transparent;
  font-size: 24px;
}

.FormCheck:active {
  transform: scale(0.95);
}

.Checked {
  border: 2px solid var(--AzulMP);
  color: var(--AzulMP);
}

.Checked svg {
  color: var(--VerdeMP);
}

.slidecontainer {
  width: 100%;
}

.NewPet fieldset div:nth-child(2) label {
  background-color: transparent;
  width: 50%;
  text-align: center;
  padding: 0 !important;
  font-size: 1.2rem;
  margin-top: 0;
  color: dimgray;
  font-weight: 500;
}

.NewPet fieldset div:nth-child(2) label:first-child {
  padding-left: 0.5rem !important;
}
.NewPet fieldset div:nth-child(2) label:last-child {
  padding-right: 0.5rem !important;
}

.NewPet .Peso {
  font-size: 32px !important;
  text-align: center !important;
  font-family: "GMedium";
  border: none !important;
}

/* ---- */

.ImageCutV2 {
  margin-top: 1rem;
  background-color: var(--AzulMP) !important;
  text-align: center !important;
  display: block;
  padding: 1rem;
  border-radius: 8px;
  min-width: calc(50% - 4px);
  color: var(--Blanco) !important;
  transition: all 0.3s;
}

/* ---- */

.ImageCut {
  position: relative;
  background-color: transparent;
  margin-top: -1rem;
  padding: 0;
  text-align: center;
}
/*Cropping pet photo */
.ReactCrop__image {
  max-height: 350px !important;
}

/*End Cropping pet photo */

.ImageCut input {
  display: none;
}

.ImageCut label {
  background-color: var(--AzulMP) !important;
  text-align: center !important;
  display: block;
  padding: 1rem;
  border-radius: 8px;

  min-width: calc(50% - 4px);

  color: var(--Blanco);
  transition: all 0.3s;
}

.ImageCut label:active {
  transform: scale(0.9);
}

.ImageCut .Btn-Link {
  background-color: transparent !important;
  color: var(--RojoMP);
}

#foto {
  margin-top: 1.5rem;
}

.FormResume {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FormResume img {
  width: 180px;
  height: 180px;

  margin-top: 32px;

  border-radius: 50%;
  border: 2px solid var(--Blanco);

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  animation-name: Giro180Y;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.FormResume p {
  text-align: center;
  font-size: 80%;
  margin-top: 4px;
  padding-left: 8px;
}

.Canvas .NewPet {
  background-color: transparent;
}

/*=== :D  Promos Bancarias ==================================== */

.PromoBancariaItem {
  background-color: transparent;
  max-height: 85vh;
  overflow-y: scroll;
}

.PromoBancariaItem li {
  margin: 8px !important;
  background-color: #d3f1ff;
  padding: 8px;
  border-radius: 8px;
}

.PromoBancariaItem li h5 {
  max-width: 80%;
  color: var(--AzulMP);
}

.PromoBancariaItem li img {
  max-width: 44px;
}

.PromosBancariasLegend {
  background-color: transparent;
  margin: 1rem 0;
  padding: 1rem 0;
  border-top: 1px solid #777777;
  font-size: 14px;
}

.PromosBancariasLegend img {
  max-width: 32px;
  max-height: 32px;
  margin: 0 4px;
  filter: grayscale(100%);
}

.PromosBancariasLegend div:last-child {
  color: var(--AzulMP);
}

/*=== :D Recientes ==================================== */

.Recientes ol {
  width: 100%;
  display: grid;
  grid-template-columns: 350px 350px 350px;
  column-gap: 16px;
  row-gap: 16px;
  justify-content: space-around;
  margin-top: 2rem;
  padding-bottom: 100px;
}

.Recientes h2 {
  grid-column: 1 / 3;
  text-align: center;
  color: var(--Blanco);
  margin: 2rem 0 0 0;
}

.Recientes hr {
  border: 2px solid var(--Blanco);
  background-color: var(--Blanco);
  border-radius: 8px;

  max-width: 70%;
  margin-left: 15%;
}

/*=== :D LittleProducBlock ==================================== */
.LittleProducBlock {
  width: 100%;
  margin-bottom: 12px;

  box-shadow: 0 0 20px -5px var(--SombraGlobal);
  background-color: var(--Blanco);
  border-radius: 8px;

  padding: 8px 12px 8px 8px;

  display: grid;
  grid-template-columns: 60px calc(100% - 64px);
  column-gap: 8px;
  animation-name: aparece;
  animation-duration: 1s;
  transition: all 0.5s;

  position: relative;
  cursor: pointer;
}

.InfoEnvio {
  grid-column: 1 / 3;
  border-top: 1px solid #eee;
  margin: 8px 0 -4px 0;
  padding-top: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.InfoEnvio img {
  max-width: 24px;
}

.InfoEnvio div {
  width: auto !important;
  color: var(--VerdeMP);
  font-size: var(--SizeM);
}

.LittleProducBlock .btn-CompraVerde {
  background-color: var(--VerdeMP);
  padding: 8px;
  width: 50%;
  border-radius: 4px;
}

.LittleProducBlock:active {
  opacity: 0.9;
}

.LittleProducBlock img {
  max-height: 100px;
  width: 60px;
  margin-right: 8px;
  transform: scale(1);
}

.LittleProducBlock div small {
  font-size: 65%;
  display: block;
  margin-bottom: 4px;
}

.LittleProducBlock .Variation {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;

  min-height: 70px;
}

.LittleProducBlock .Variation li {
  background-color: rgba(31, 168, 231, 0.6) !important;
  border-radius: 4px;
  color: var(--Blanco);
  font-size: 12px;
  margin: 0 8px 4px 0 !important;
  padding: 8px 4px;
  border: none !important;
  cursor: pointer;
}

.LittleProducBlock .Variation .active {
  background-color: var(--AzulMP) !important;
}

.LittleProducBlock h4 {
  color: #777777;
}
.LittleProducBlock b {
  background-color: rgba(255, 255, 255, 0.9);
  align-items: flex-end;
  justify-content: flex-start;
}

.LittleProducBlock .LimitSize {
  max-width: 50%;
}

.Botonera {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/*=== :D Items Favoritos ==================================== */

.FavoritProduct {
  width: 100%;
  animation-name: aparec; /*rompo la animacion a propósito dado que en LittleOrder el efecto se rompe*/
  animation-duration: 1s;
}

.FavoritProduct h4 {
  padding: 2px;
  margin: 0;
}

.FavoritProduct span {
  font-size: var(--SizeS);
  margin-top: 8px;
}

.FavoritProduct .Btn-Action {
  font-size: 12px;
  height: 32px;
  max-width: calc(50% - 4px);
  margin-top: 8px;
}

.FavoritProduct .Btn-Outline {
  background-color: var(--Blanco);
  color: var(--AzulMP);
  border: 2px solid var(--AzulMP);
}

.FavoritProduct button::first-letter {
  text-transform: capitalize;
}

.FavoritProduct .Btn-Delete {
  position: absolute;
  z-index: 1;
  top: -10px;
  left: -10px;

  width: 32px;
  height: 32px;
  background-color: var(--Blanco);
  box-shadow: 0 0 10px -2px var(--SombraGlobal);
  border-radius: 50%;
  line-height: 32px;
  text-align: center;
  transform: scale(1);
  color: var(--RojoMP);
}

.FavoritProduct .Duration {
  margin-top: 8px;
}

.FavoritProduct .Duration input {
  border: 1px solid var(--AzulMP);
  padding: 8px;
  border-radius: 4px;
  background-color: var(--AzulSuave);
  text-align: center;

  max-width: calc(100% - 78px);
}

.FavoritProduct .Duration .success {
  background-color: var(--AzulMP);
}
.FavoritProduct .Duration .cancel {
  color: var(--RojoMP);
}

.Duration .success,
.Duration .cancel {
  color: white;
  font-size: 16px;
  width: 32px;
  height: 32px;
  width: 100%;
  margin-left: 4px;
  border: none;
  border-radius: 4px;
}

/*=== :D Similares ==================================== */

.Similares {
  display: block;
  background-color: transparent;
  width: calc(100% + 16px);
  margin-left: -8px;
  overflow: hidden;

  margin-bottom: 32px;
}

.Similares .SimilaresTitle {
  font-size: 14px;
  color: #777777;
  padding: 8px;
  margin: 32px 0 8px 0;
}

.RowSimilares {
  padding: 1rem 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: scroll;
  width: 100%;
}

.RowSimilares .LittleProducBlock {
  margin: 0 1rem;
  min-width: calc(100% - 2rem);
}

.RowSimilares .Relleno {
  opacity: 0;
}

/*=== :D  Loading ==================================== */
.Loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  text-align: center;
  top: 0;
  left: 0;
  z-index: 1;
}

.Loading svg {
  font-size: 32px;
  margin: 16px;
  animation: Giro360 1.5s infinite linear;
}

.Loading-Normal svg {
  color: var(--AzulMP);
}

.Loading-Normal h5 {
  font-size: 18px;
  color: var(--AzulMP);
}

.Skeleton {
  position: relative;
  overflow: hidden;
  background-color: #d3f1ff;
  border-radius: 8px;
}

.Rounded {
  border-radius: 50%;
}

.Skeleton::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 200%;
  background-image: linear-gradient(
    to left,
    transparent,
    #1fa8e780,
    #1fa8e780,
    transparent
  );
  top: -50%;
  left: -50px;
  color: #1fa8e780;
  animation: skeletonAnimation 0.5s infinite linear;
}

@keyframes skeletonAnimation {
  0% {
    left: -50px;
    opacity: 1;
  }
  50% {
    left: calc(100% + 50px);
    opacity: 0;
  }
  100% {
    left: -50px;
    opacity: 0;
  }
}

/*=== :D  Edicion de mascota, vista ==================================== */

.EditPep {
  display: grid;
  grid-template-columns: 100%;

  justify-self: flex-end;
}

.EditPep h2 span {
  text-transform: capitalize;
}

/*=== :D  Queries para Responsive ==================================== */

.ConBadge {
  position: relative;
}

.ConBadge::after {
  content: "";
  position: absolute;
  top: -2px;
  right: -4px;
  background-color: #ffd700;
  width: 12px;
  height: 12px;
  z-index: 1;
  border-radius: 50%;
}

.MiCuenta ul .ConBadge::after {
  /* Las tiles de UserAccount tienen una escala inferior, asiq de esta forma se corrge*/
  transform: scale(1.11);
}

/*=== :D  Perfil de la mascota ==================================== */
.PetProfile {
  margin-top: 50px;
  width: 100vw;
  position: relative;
  height: calc(100vh - 50px);
}
.SlideBox {
  background-color: transparent;
  overflow: hidden;
  display: flex;
}

.PrimaryBox,
.SecondaryBox {
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  transition: all 0.3s;
}

.PrimaryBox {
  background-color: transparent;
  transform: translateX(-100%);
}

.SecondaryBox {
  background-color: transparent;
  transform: translateX(-100%);
}

.SecondaryBox .ConScroll {
  max-height: calc(100vh - 70px);
  overflow-y: scroll !important;
}

.PetProfileBlock {
  background-image: linear-gradient(-45deg, white, white);
  margin: 16px 8px 8px 8px;
  padding: 4px 12px 4px 4px;
  border-radius: 8px;
  box-shadow: 10px 10px 15px -15px var(--SombraGlobal);
}

.LittlePetItemImg {
  width: 44px;
  height: 44px;
}

.PetProfileBlock h5 {
  font-size: 18px;
  padding-left: 8px;
}

.PetProfile ol {
  display: grid;
  grid-template-columns: auto auto auto;
}

.PetProfile .Tile {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.PetProfile .PetForm {
  height: 100%;
  overflow-y: scroll;
  padding: 0 8px;
}

.PetProfile label {
  margin: 4px 0 12px 0 !important;
  font-size: 20px !important;
}

.PetProfileNavigation {
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: calc(100%) !important;
  padding: 8px;
  margin: -8px;

  box-shadow: 0 0 20px -5px var(--SombraGlobal);
}

.PetProfile .SecondaryBox ol li {
  width: 100%;
}

.PetProfile .SecondaryBox .FotoMascota {
  widows: 180px;
  height: 180px;
  margin: 12px auto;
  border-radius: 50%;
}

/*=== :D  SingUp ==================================== */
.SingUp {
  padding: 8px;
}

.SingUp svg {
  width: 20px;
  height: 20px;
}

.SingUp span {
  width: 100%;
  text-align: left;
  padding-left: 16px;
}

.Recordarme {
  background-color: transparent;
  margin: 12px 0 12px 0;
}

.Recordarme div,
.Recordarme a {
  background-color: transparent;
  width: 100%;
}

.Recordarme a {
  text-align: center;
  font-size: var(--SizeS);
}

.Recordarme label {
  margin-left: 8px;
}

.Login {
  margin-top: -8px;
  background-color: transparent;
  padding: 0;
  height: 44px;
  line-height: 44px;
}

.Login h3 {
  background-color: transparent;
  padding: 0;
  margin: 0 !important;
}

.Login div {
  background-color: transparent;
  max-width: 35%;
}

.Login div button {
  width: 100%;
  height: 44px;
  border: none;
  background-color: transparent;
  margin-left: 2px;
  border-radius: 8px;
}

.Login button svg {
  width: 24px;
  height: 24px;
  font-size: 32px;
}

.SingUpBox {
  overflow: hidden;
}

.SingUpBox .Especial div {
  display: flex;
  margin-top: 8px;
  height: 40px;
  border: 1px solid var(--AzulMP);
  border-radius: 8px;
  overflow: hidden;
  width: calc(50% - 4px);
  background-color: var(--AzulSuave);
}

.SingUpBox .Especial svg {
  margin: 0 0 0 14px !important;
  font-size: 20px;
  color: var(--AzulMP);
}

.SingUpBox .Especial label {
  height: 40px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  color: var(--AzulMP);
  font-weight: bold;
}

.SingUpBox .Especial div:nth-child(1) input {
  border: none;
  max-width: 44px;
  height: 40px;
  text-align: center;
}

.SingUpBox .Especial div:nth-child(2) input {
  border: none;
  max-width: 70%;
  height: 40px;
  text-align: center;
}

.LoginButtonOptions button {
  width: calc(100% - 16px);
  justify-content: start;
  padding: 8px;
  margin: 8px 0 8px 8px;
  background-color: whitesmoke;
  border: 1px solid #eee;
  border-radius: 4px;
}

.LoginButtonOptions button svg {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.LoginButtonOptions button span {
  margin: 0;
  font-weight: bold;
  padding-left: 8px;
}

/*=== :D Formularios Globales  ==================================== */

.FormGroup {
  background-color: transparent;
  width: 100%;
  margin: 8px 0 0 0;
  border: none;

  position: relative;
}

.FormGroup input {
  padding: 12px 12px 12px 40px;
  border: 1px solid var(--AzulMP);

  border-radius: 8px;
  flex: auto;
  width: 100%;
}

.FormGroup svg {
  background-color: transparent;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 12px;

  color: var(--AzulMP);
  font-size: 20px;

  transform: translateY(-50%);
}

.FormGroupDuo input {
  width: calc(50% - 4px);
}

.FormGroupDuo input:last-child {
  margin-left: 8px;
  padding: 12px 12px 12px 12px;
}

.FormGroupDisabled input {
  background-color: var(--AzulSuave);
}

.SubmitBox .w100 {
  width: calc(50% - 4px) !important;
}

.TagBox {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap !important;
  padding: 0 8px 8px 8px;
}

.Tag svg {
  margin-left: 12px;
  color: white;
}

.Tag {
  background-color: white;
  color: var(--AzulMP);
  margin: 2px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  text-transform: capitalize;
  border: 1px solid #1fa9e7;

  box-shadow: -18px 0 0 #1fa9e7 inset;
}

.PetProfile .PetForm fieldset {
  background-color: white;
  margin: 12px 0;
  padding: 8px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 20px -10px var(--SombraGlobal);

  display: flex;
  flex-flow: column;
}

.PetProfile .PetForm fieldset label {
  font-size: var(--SizeM) !important;
  width: 100% !important;
}

.PetProfile .PetForm fieldset input {
  border: 1px solid #eee;
  border-radius: 8px;
  width: 100%;
}

.PetProfile .PetForm fieldset b {
  padding-right: 8px;
}

.css-2b097c-container {
  width: 100%;
}

/*=== :D Lista de direcciones ==================================== */
.AddressList {
  padding: 0 8px;
  background-color: transparent;
}

.UserAddresList li {
  color: var(--AzulMP);
  margin: 0 0 12px 0;
  position: relative;
  cursor: pointer;
}

.UserAddresList li h5 {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "GRegular";
  width: 80%;
}

.AddresDelete {
  border: none;
  background-color: var(--Blanco) !important;
}

.AddresDelete svg {
  color: var(--RojoMP);
  font-size: 18px;
}

/*=== :D  Lista de veterias ==================================== */

.VetsList {
  padding: 16px 8px 32px 8px;
}

/*=== :D  Pagina de seguro ==================================== */

.SeguroPage {
  padding: 54px 0;
}

.SeguroPage .SlideBox {
  width: 100vw;
}

.SeguroPage .PrimaryBox .Card,
.SeguroPage .SecondaryBox .Card {
  width: calc(100% - 16px);
  margin: 16px 8px;
}

.SeguroPage .Loading {
  position: initial;
  height: 90vh;
}

/*=== :D  CrossSaling Sugerencias ==================================== */

.SuggestionBox {
  background-color: transparent;
}

.SuggestionBox ol {
  padding: 0 8px;
}

.SuggestionItem {
  padding: 8px;
}

.SuggestionItem img {
  max-width: 44px;
  max-height: 70px;
  margin-right: 8px;
}

.SuggestionItem .Btn-Action {
  max-width: 108px;
  border-radius: 4px;
}

/*=== :D  botones fijos de la prte inferiro en mobile ==================================== */

.PaymentMethod .FixedBottom,
.PurchaseOption .FixedBottom {
  background-color: white;
  width: 100vw !important;
  margin-left: -8px;
  bottom: 0;
  padding: 8px;
  box-shadow: 0 0 20px -10px var(--SombraGlobal);
}

.PaymentMethod .FixedBottom .btn-CompraVerde,
.PurchaseOption .FixedBottom .btn-CompraVerde {
  width: 100%;
  font-size: var(--SizeS) !important;
}

.PaymentMethod .FixedBottom div,
.PurchaseOption .FixedBottom div {
  font-size: var(--SizeM);
}

.PaymentMethod .FixedBottom div b,
.PurchaseOption .FixedBottom div b {
  font-size: var(--SizeL);
}

/*=== :D  Vendedores y Preguntas Frecuentes ==================================== */

.Vendedores {
  text-align: center;
}

.Vendedores .bloqueUno {
  background-color: var(--AzulMP);
  padding-top: 70px;

  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
}

.Vendedores .bloqueUno h1 {
  color: var(--Blanco);
  padding: 8px 16px;
  text-align: center;
}

.Vendedores .bloqueUno span {
  color: var(--NegroMP);
  font-size: var(--SizeM);
  font-weight: bold;
  padding: 0 16px;

  text-align: center;
  display: block;
}

.Vendedores .bloqueUno p {
  color: var(--Blanco);
  font-size: var(--SizeM);
  padding: 8px 16px;
  text-align: center;
}

.Vendedores .bloqueUno .tiendita {
  width: 80vw;
  margin: auto;
  padding-left: 16px;
}

.Vendedores .bloqueDos {
  overflow: hidden;
  max-width: 100vw;

  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: var(--Blanco);
  box-shadow: inset 0 75px 0 var(--AzulMP);
}

.Vendedores .bloqueDos .nubes {
  margin-top: 32px;
  margin-bottom: -8px;
}

.Vendedores .bloqueDos h1 {
  padding: 16px;
  color: var(--AzulMP);
  font-size: 24px;
}

.Vendedores .bloqueDos .left,
.Vendedores .bloqueDos .right {
  display: none;
}

.Vendedores .bloqueDos ol {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 32px 0;
}

.Vendedores .bloqueDos ol li img,
.Vendedores .bloqueTres ol li img {
  height: 54px;
  display: block;
  margin: auto;
}

.Vendedores .bloqueDos ol li:last-child img {
  height: 44px;
}

.Vendedores .bloqueDos ol li span {
  width: 70%;
  margin: 16px auto;
  display: block;
  background-color: transparent;
  font-size: var(--SizeM);
}

.Vendedores .bloqueTres {
  background-color: var(--DoradoSuave);
}

.Vendedores .bloqueTres ol {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 32px 0;
}

.Vendedores .bloqueTres ol li img {
  height: 128px;
}

.Vendedores .bloqueTres ol li h2 {
  color: var(--AzulMP);
  font-size: var(--SizeM);
  padding: 12px 0 8px 0;
}

.Vendedores .bloqueTres ol li p {
  color: var(--NegroMP);
  font-size: var(--SizeL);
  width: 80%;

  margin: 0 auto 32px auto;
}

.Vendedores .versatil .dotor {
  display: none;
}

.Vendedores .bloqueCuatro {
  background-image: linear-gradient(
    135deg,
    var(--DoradoMP),
    var(--DoradoMP),
    orange
  );
  padding: 32px;
  display: flex;
  align-items: center;
  flex-flow: column;
}

.Vendedores .bloqueCuatro h1 {
  padding: 8px;
  color: var(--NegroMP);
  font-size: 32px;
  margin-bottom: 16px;
}

.Vendedores .bloqueCuatro ol li {
  background-color: white;
  padding: 32px 16px;
  margin: 8px 0;

  border-radius: 4px;
  box-shadow: var(--SombraGlobal) 0 0 20px -5px;
}

.Vendedores .bloqueCuatro span {
  display: block;
  font-weight: 900;
  color: var(--AzulMP);
  font-size: 32px;
}

.Vendedores .bloqueCuatro p {
  font-size: var(--SizeM);
  margin-bottom: 4px;
  font-weight: 900;
  margin-top: 8px;
}

.Vendedores .bloqueCinco {
  padding: 32px;
  background-image: linear-gradient(
    135deg,
    var(--AzulMP),
    deepskyblue,
    deepskyblue
  );
}

.Vendedores .bloqueCinco h1 {
  color: var(--Blanco);
  font-size: 24px;
  margin-bottom: 32px;
}

.Vendedores .bloqueCinco ol img {
  margin: 8px auto;
  display: block;
}

.Vendedores .bloqueCinco ol span {
  color: var(--DoradoMP);
  font-size: 22px;
  font-weight: bold;
}

.Vendedores .bloqueCinco ol p {
  color: var(--AzulSuave);
  font-weight: bold;
  margin: 8px 0 32px 0;
  font-size: var(--SizeM);
}

.Vendedores .bloqueSeis {
  background-color: var(--ColorFondo);
  padding: 16px;
  position: relative;
}

.Vendedores .bloqueSeis h1 {
  font-size: var(--SizeL);
  color: var(--AzulMP);
  font-size: 22px;
  padding: 32px 0;
}

.Vendedores .bloqueSeis ul li {
  padding: 16px;
  margin-bottom: 22px;
  border-radius: 4px;

  transition: all 0.3s;
  cursor: pointer;

  background-color: white;
  box-shadow: 0 0 15px -5px var(--SombraGlobal);
}

.Vendedores .bloqueSeis ul li h4 {
  font-size: 14px;
  margin-bottom: 16px;
  color: var(--NegroMP);
}

.Vendedores .bloqueSeis ul li p {
  font-size: 12px;
  text-align: left;
  color: var(--NegroMP);
}

.mapaVersatil {
  background-image: url("../../IMG/mapa.png");
  box-shadow: inset 0 2080px 0 rgba(0, 0, 0, 0.4);

  display: grid;
  grid-template-columns: auto;
  align-items: center;

  padding: 16px 0;
}

.Vendedores .bloqueSiete {
  width: 90%;
  margin: 16px auto;
  padding: 8px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-shadow: 0 0 20px var(--CistralBlanco);
}

.Vendedores .bloqueSiete ol li {
  padding: 8px;
  margin: 8px;
}

.Vendedores .bloqueSiete ol li svg {
  color: var(--AzulMP);
  font-size: 40px;
  display: block;
  margin: 4px auto 8px auto;
}

.Vendedores .bloqueSiete ol li span {
  text-align: left;
  font-size: var(--SizeM);
  color: var(--NegroMP);
  font-weight: bold;
}

.Vendedores .bloqueSiete fieldset {
  border: none;
  padding: 8px;
}

.Vendedores .bloqueSiete fieldset label {
  display: block;
  text-align: left;
  text-transform: capitalize;
  padding-bottom: 8px;
}

.Vendedores .bloqueSiete fieldset input {
  width: 100%;
  padding: 12px 8px;
  border: none;
  border-radius: 4px;
  background-color: whitesmoke;
  color: var(--AzulMP);
  font-weight: bold;
}

.Vendedores .bloqueSiete button {
  background-color: var(--VerdeMP);
  padding: 16px 32px;
  color: white;
  border-radius: 4px;
  border: none;
  margin-top: 32px;
  width: calc(100% - 16px);
  font-weight: bold;
  font-display: var(--SizeL);
  text-transform: uppercase;
}

.Vendedores .bloqueOcho {
  background-color: #313131;
}

.Vendedores .bloqueOcho button {
  background-color: white;
  border-radius: 8px;
  border: none;
  margin: 8px;

  width: 54px;
  height: 54px;
}

.Vendedores .bloqueOcho button img {
  width: 32px;
  height: 32px;
  margin-bottom: -2px;
}

/*=== :D  Footer ==================================== */

footer {
  padding: 32px;

  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: var(--ColorFooter);

  box-shadow: 3000px 0 0 var(--ColorFooter), -3000px 0 0 var(--ColorFooter);
  z-index: 1;
}

footer ul {
  background-color: transparent;
  width: 90vw;
}

footer ul li {
  background-color: transparent;
  padding-bottom: 16px;
  margin-bottom: 16px;

  border-bottom: 1px solid #ddd;
}

footer ul li h4 {
  color: var(--NegroMP);
  text-transform: uppercase;
  font-size: var(--SizeM);
}

footer ul li ol {
  background-color: transparent;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
}

footer ul li:hover ol {
  max-height: 1024px;
}

footer ul li:last-child {
  border-bottom: none;
}

footer ul li ol li {
  background-color: transparent;
  padding: 8px 0;
  margin: 8px 0 0 0;

  transition: all 0.3s;
  cursor: pointer;
  border-bottom: none;
  font-size: var(--SizeM);
}

footer ul li ol li:hover {
  color: var(--AzulMP);
}

footer li input {
  border-radius: 4px;
  padding: 12px 8px;
  width: 100%;
  border: 1px solid #ddd;
}

footer li .Btn-Action {
  background-color: var(--AzulMP);
}

footer ul li ol li img {
  max-width: 120px;
}

footer dl .footerLogos img,
footer dl .footerLogos svg {
  max-width: 32px;
  max-height: 32px;
  margin: 12px 0 0 12px;
  cursor: pointer;
}

footer dl .OliverLogoFooter svg {
  max-height: 50px;
  max-width: 100px;
  background-color: transparent;
  opacity: 0.8;
}

footer dl {
  display: flex;
  flex-direction: column;
}

footer dl li {
  background-color: transparent;
  font-size: var(--SizeM);
  padding-bottom: 24px;
}

footer dl li .RowCenter {
  justify-content: flex-start;
}

/*=== :D Terminos ==================================== */

.Terminos {
  background-color: var(--ColorFondo);
  padding: 80px 0;
}

.Terminos article {
  background-color: white;
  padding: 12px;
  margin: 8px;
  box-shadow: 0 0 20px -5px var(--SombraGlobal);
  border-radius: 8px;
}

.Terminos article h3 {
  margin: 32px 0 8px 0;
  color: var(--AzulMP);
  text-align: center;
}

.Terminos article small {
  font-weight: bold;
  color: var(--NegroMP);
  text-align: center;
  display: block;
}

.Terminos article p {
  margin-top: 8px;
  font-size: var(--SizeM);
}

.Terminos article h4 {
  margin-top: 32px;
  color: var(--NegroMP);
  padding-bottom: 4px;
}

.Terminos article hr {
  border-color: var(--CistralNegro);
}

.carousel {
  background-color: transparent;
  margin-bottom: 32px !important;
  cursor: pointer;
}

.carousel .slider-wrapper img {
  border-radius: 8px;
  width: 90%;
  cursor: pointer;
  /*height: 120px;*/
}

/*=== :D  Confian en nosotros ==================================== */

.TheyTrustUs {
  background-color: var(--Blanco);
  padding: 32px 0;
}

.TheyTrustUs img {
  max-width: 40vw;
}

.TheyTrustUs h4 {
  text-align: center;
  margin-bottom: 16px;
  font-weight: lighter;
  color: var(--NegroSuave);
}

.TheyTrustUs ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.TheyTrustUs ul li {
  background-color: transparent;
  margin: 8px;
}

.TheyTrustUs ul img {
  max-height: 20px;
}

.TheyTrustUs .RowCenter {
  justify-content: center;
  margin-bottom: 16px;
}

.TheyTrustUs .RowCenter img {
  margin: 0 4px;
  height: 29px;
}

/*=== :D  Btn-SelectCountry ==================================== */

.Btn-SelectCountry {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  margin-left: 12px;
  border: 2px solid var(--Blanco);
  border-radius: 8px;
  background-color: var(--CistralBlanco);
  transition: all 0.3s;
  cursor: pointer;
}

.Btn-SelectCountry:hover {
  background-color: var(--Blanco);
}

.Btn-SelectCountry:hover span {
  color: var(--NegroMP);
}

#mexico,
#argentina,
#chile {
  width: 20px;
  height: 20px;
}

.Btn-SelectCountry span {
  color: var(--Blanco);
  margin-left: 8px;
}

.CountrysOption {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.CountrysOption li {
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 0 12px;
  border: 2px solid var(--ColorFondo);
  height: 44px;
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
}

.CountrysOption span {
  color: var(--NegroMP);
  font-weight: bold;
  margin-left: 8px;
}

.CountrysOption li:hover {
  padding: 0 24px;
}

.CountrysOption .Disabled {
  background-color: var(--ColorFondo);
  opacity: 0.4;
  cursor: no-drop;
}

.CountrysOption .Disabled:hover {
  padding: 0 12px;
}

/*=== :D  Queries para Responsive ==================================== */
@media only screen and (max-width: 768px) {
  /* mediacompu */

  .IndProdHead img {
    max-width: 200px;
    min-width: auto;
  }
  .PurchaseOption {
    padding: initial;
  }
  .PaymentMethod {
    margin-top: 0;
  }

  .LittleProducBlock .Variation {
    min-height: 0 !important;
  }
}
@media only screen and (min-width: 768px) {
  /* mediacompu */
  .BoxCarrito {
    overflow: hidden;
  }

  .BlueBox {
    margin-top: -100px !important;
  }

  body {
    padding-top: 0;
  }

  .IndProdVariations,
  .IndProdVariations .RowCenter {
    display: grid;
    grid-template-columns: 30% 70%;
  }

  .IndProdVariations h4,
  .IndProdVariations .RowCenter {
    grid-column: 1 / 3;
  }

  .IndProdVariations .totalizador {
    display: initial;
    background-color: var(--ColorFooter);
    border-radius: 8px;
    padding: 16px 8px 8px 8px;
    margin-bottom: 8px;
  }

  .totalizador .oldPrice {
    margin-top: 8px;
    font-size: var(--SizeL);
  }

  .totalizador .newPrice {
    font-size: 32px;
    color: var(--RojoMP);
    font-weight: bold;
  }

  .BannerImagesDesk {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: center;
    align-items: center;
    column-gap: 16px;

    margin: 164px 0 44px 0 !important;
  }

  .BannerImagesDesk img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 20px -3px var(--CistralNegro);
    cursor: pointer;
    transform: scale(0.95);
    transition: all 0.3s;
  }

  .BannerImagesDesk img:hover {
    transform: scale(1);
  }

  .carousel {
    width: 90vw !important;
    max-width: 1440px;
    background-color: transparent;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .LoginButtonOptions button {
    width: 100%;
    margin: 0 0 8px 0 !important;
  }

  .marcasCentradas {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Terminos {
    display: grid;
    grid-template-columns: 720px;
    justify-content: center;
  }

  footer {
    padding: 64px 0 32px 0;
  }

  footer ul {
    background-color: transparent;
    width: 1024px;
    display: flex;
  }

  footer ul li {
    width: 25%;
    border: none;
  }

  footer ul li ol {
    max-height: 1024px;
    width: 100%;
    background-color: transparent;
  }

  footer .appStoreImg img {
    margin: 0;
    width: 100%;
  }

  footer ul li ol li {
    background-color: transparent;
    width: 100%;
    padding-left: 0;
  }

  footer dl {
    margin-top: 32px;
    flex-direction: row;
    justify-content: space-between;
  }

  footer dl li {
    text-align: center;
    background-color: transparent;
    padding-bottom: 0;
  }

  footer dl li img,
  footer dl li svg {
    margin: 8px;
  }

  .PetBanner {
    background-color: transparent;
    grid-template-columns: 450px 450px;
    box-shadow: 0 -340px 0 white inset;
    padding: 200px 0 32px 0;

    margin-top: -50px;
    overflow: hidden;
  }

  .PetBanner::before {
    background-color: white;

    position: absolute;
    content: "";
    top: 100px;
    left: 0;

    width: 100%;
    height: 400px;

    border-radius: 50%;
    transform: scale(1.1);
  }

  .PetBanner div {
    display: flex;
    flex-flow: column;
    align-items: center;

    background-color: transparent;
    z-index: 1;
  }

  .PetBanner div svg,
  .PetBanner div img {
    max-width: 400px;
    height: auto;
  }

  .PetBanner h3 {
    color: var(--NegroMP);
    grid-column: 1 / 3;
    margin-top: 0;
  }

  .Vendedores .bloqueUno {
    grid-template-columns: 520px 420px;
    justify-content: center;
    align-items: center;
  }

  .Vendedores .bloqueUno div {
    width: 520px;
  }

  .Vendedores .bloqueUno div h1 {
    font-size: 40px;
  }

  .Vendedores .bloqueUno h1,
  .Vendedores .bloqueUno span,
  .Vendedores .bloqueUno p {
    text-align: left;
  }

  .Vendedores .bloqueUno .tiendita {
    max-width: 420px;
    padding-left: 0;
  }

  .Vendedores .bloqueDos h1 {
    font-size: 32px;
    padding: 32px 0;
  }

  .Vendedores .bloqueDos iframe {
    height: 350px;
    width: 600px;
  }

  .Vendedores .bloqueDos .left,
  .Vendedores .bloqueDos .right {
    display: initial;
  }

  .Vendedores .bloqueDos ol,
  .Vendedores .bloqueTres ol {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .Vendedores .bloqueDos ol li {
    width: 250px;
  }

  .Vendedores .bloqueTres ol li {
    width: 280px;
  }

  .Vendedores .versatil {
    display: grid;
    grid-template-columns: 50vw 50vw;
    position: relative;
  }

  .Vendedores .versatil ol {
    z-index: 2;
  }

  .Vendedores .bloqueCinco ol {
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: space-around;
    align-items: flex-start;

    padding-bottom: 150px;
  }

  .Vendedores .bloqueCinco ol p {
    font-size: 12px;
    text-align: left;
  }

  .Vendedores .versatil .dotor {
    display: initial;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-52%);
    z-index: 0;
  }

  .Vendedores .bloqueSeis {
    box-shadow: 0 -15px 15px -20px var(--SombraGlobal);
  }

  .Vendedores .bloqueSeis h1 {
    font-size: 40px;
    padding: 32px 0;
  }

  .Vendedores .bloqueSeis ul {
    display: grid;
    grid-template-columns: 620px;
    justify-content: center;
    align-items: center;
  }

  .Vendedores .bloqueSeis ul li:hover {
    transform: scale(1.1);
    box-shadow: 0 0 150px 0px var(--SombraGlobal);
  }

  .mapaVersatil {
    grid-template-columns: 420px 420px;
    column-gap: 54px;
    justify-content: center;
  }

  .Vendedores .bloqueSiete {
    width: 100%;
    margin: 85px 0;
    background-color: rgba(255, 255, 255, 0.8);
    transition: all 0.3s;
  }

  .mapaVersatil:hover .bloqueSiete {
    background-color: rgba(255, 255, 255, 1);
  }

  .mapaVersatil:hover fieldset input {
    background-color: whitesmoke;
  }

  .HomeNavbar {
    box-shadow: 50vw 0 0 var(--AzulMP), -50vw 0 0 var(--AzulMP);
  }

  .PurchaseOption .FixedBottom,
  .PaymentMethod .FixedBottom,
  .PurchaseMade .Botonera {
    position: initial;
    width: 100% !important;
    margin: 0;
    border-radius: 8px;
  }

  .PurchaseMade {
    margin-top: 0;
  }

  .PurchaseMade .PurchaseMadeAnimation {
    grid-column: 1 / 3;
  }

  .CardBottom .PurchaseMade {
    display: initial;
    flex-flow: column;
  }

  .BrandHeader .Auxiliar {
    display: none;
  }

  .NavbarGoBack {
    padding: 0 9vw;
  }

  .RateGold span,
  .Delivery span {
    font-size: 14px;
    margin-left: 4px;
  }

  .PetProfile {
    width: 375px;
    margin-top: -8px;
    padding-top: 12px;
  }

  .PetProfile .FixedBottom {
    bottom: 16px;
  }

  .OmiCalendar {
    margin: 2rem 0;
  }

  .EditPep h2,
  .Similares {
    grid-column: 1 / 3;
  }

  .RowSimilares .LittleProducBlock {
    min-width: 350px !important;
    max-width: 350px;
  }

  .EditPep,
  .IndividualProduct,
  .PurchaseOption,
  .PurchaseMade,
  .PaymentMethod {
    display: grid;
    grid-template-columns: 620px 620px;
    justify-content: center;
    align-items: flex-start;
    column-gap: 24px;
    padding-top: 100px;
    min-height: 95vh;
  }

  .PurchaseOption .btn-blue {
    max-width: 1000px !important;
  }

  .IndividualProduct .btn-CompraVerde {
    position: relative;
    width: calc(100% + 8px);
    margin-top: 8px;
    border: none;
  }

  .VisibleOnlyDesktop {
    display: initial;
  }

  .VisibleOnlyMobile {
    display: none;
  }

  .CloseDrawer {
    display: inline;
  }

  .DrawerGeneric {
    box-shadow: 0 0 20px var(--SombraGlobal);
  }

  .BtnAddress {
    width: 250px !important;
  }

  .BtnAddress p {
    background-color: transparent;
    width: 100%;
    text-align: left;
  }

  .CardBottom {
    position: fixed;
    left: 16px;
    bottom: 0;
    width: 375px;
    max-height: 440px;
    transform: translateY(390px);
  }

  .CardBottom ol {
    height: 400px;
    overflow-y: scroll;
  }

  /* --------------- */

  .AddressList {
    width: 100%;
  }

  .AddressList div h2 {
    background-color: transparent;
    padding: 0;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }

  .AddressItems li {
    width: 150px;
    height: 150px;
    margin-bottom: 0.5rem;
  }

  .AddressItems li:hover {
    cursor: pointer;
  }

  .AddressItems li svg {
    font-size: 80px;
  }

  /*.AddressList article{
    box-shadow: 0 0 15px var(--SombraGlobal) !important;
    background-color: var(--Blanco) ;
    height: calc(100vh - 60px);
    width: 375px;
    margin-top: 1rem;
    padding: 1rem;
  
    position: fixed;
    bottom: 0;
    right: 0;
  
    overflow-x: scroll;
  
    transform: translateX(50vh);
    opacity: 0;
  }*/

  .PaymentMethod {
    padding-top: 32px;
  }

  .PaymentMethod h2 {
    grid-column: 1/3;
  }

  .PaymentMethod .btn-CompraVerde {
    position: initial;
    width: 100% !important;
  }

  .PurchaseOption {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }

  .PurchaseOption .ListaHorarios,
  .PurchaseOption .listaCarrito {
    width: 620px;
  }

  .PurchaseOption .ListaCarrito ol {
    max-height: 500px;
    overflow-x: scroll;
  }

  .PurchaseOption .ListaCarrito ol img {
    max-height: 80px;
  }

  .PurchaseOption .btn-Disabled,
  .PurchaseOption .btn-CompraVerde {
    position: initial;
  }

  .BoxPromociones article:first-child h1 {
    padding-top: 5rem;
    font-size: 64px;
  }
  .BoxPromociones article:first-child img {
    max-width: 50vw;
    margin-top: -10%;
  }

  .BoxPromociones article:last-child {
    background-color: var(--Blanco);
    margin-top: -3rem;
    width: 100vw;
    overflow: hidden;
    grid-template-columns: auto auto auto auto;
    justify-content: space-evenly;
  }

  .BoxPromociones article:last-child a {
    width: 100%;
    margin: 0 0 -0.5rem 0;
    transform: scale(0.9) translateY(0.5rem);
    box-shadow: 0 3px 0 1px whitesmoke;
  }

  .BoxPromociones article:last-child a:hover {
    transform: scale(0.9) translateY(0.5rem);
    box-shadow: 0 0 20px -8px lightgray;
    transform: scale(0.92) translateY(-0.5rem);
  }

  .BoxPromociones article:last-child a img {
    width: 100%;
  }

  .Btn {
    padding: 1rem 2rem;
  }

  .BrandContainer {
    width: 80vw;
    margin: 2rem 0 2rem 10vw;
    background-color: transparent;
  }

  .BrandContainer ol::-webkit-scrollbar {
    width: 0;
    height: 8px;
  }

  .BrandContainer ol::-webkit-scrollbar-track {
    background: rgba(245, 245, 245, 0.1);
    border-radius: 8px;
  }

  .BrandContainer ol::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    transition: all 0.3s;
  }

  .BrandContainer ol::-webkit-scrollbar-thumb:hover {
    background: var(--Blanco);
    border-radius: 8px;
  }

  .BrandContainer::before {
    bottom: -20px;
  }
  .ProductList {
    display: grid;
    grid-template-columns: 350px 900px;

    justify-content: center;
    padding-top: 32px;
  }

  .Paginator {
    position: sticky;
    bottom: 16px;
    left: 60%;
    transform: translateX(-50%);

    width: 40%;
    margin: 32px 0 -52px 0;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    column-gap: 1px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f3f3f3;
    box-shadow: 0 0 20px -10px var(--SombraGlobal);
  }

  .Paginator .Paginator-Item {
    min-width: 35px;
    line-height: 44px;
    border: none;
    background-color: white;
    color: #222;
    transition: all 0.3s;
    text-align: center;
  }

  .Paginator .Paginator-Item:hover {
    background-color: #d4f1ff;
  }

  .Paginator button:first-child,
  .Paginator button:last-child {
    background-color: #d4f1ff;
    color: #1fa9e7;
  }

  .Paginator .active {
    color: #1fa9e7;
  }

  .Filters {
    position: initial;
    padding: 0;
    transform: none;
    height: 100px;
    box-shadow: none;
    background-color: transparent;
  }

  .BotonDeFiltro {
    background-color: plum;
  }

  .CustomFilters {
    background-color: var(--Blanco);
    height: auto;
    width: 350px;
    margin: 8px 0 0 0;
    padding: 16px 0.5rem 0 0.5rem;
    box-shadow: 0 0 20px -8px var(--SombraGlobal);
    border-radius: 16px;
  }

  .FiltersTopNav {
    box-shadow: 0 0 20px -8px var(--SombraGlobal);
    border-radius: 16px;
    background-color: var(--Blanco);
    max-height: 3000px;
    transition: all 0.3s;
    margin-top: 8px;
  }

  .FiltersTopNav .CustomFilters {
    box-shadow: none;
    margin: 0;
  }

  .FiltersTopNav .CloseFiltersTopNav {
    display: none;
  }

  .ProductList .ProductListContainer {
    width: 900px;
    display: grid;
    grid-template-columns: 290px 290px 290px;
    justify-content: flex-end;
  }

  .Carrito .BoxCarrito {
    position: absolute;
    top: 60px;
    right: 0;

    width: 366px;

    transform: translateX(200px) translateY(-200px) scale(0);
    opacity: 0;
    z-index: 1000;

    padding: 8px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 0 20px -5px var(--SombraGlobal);
  }

  .Carrito .BoxCarrito ul {
    border-radius: 8px;
    padding-bottom: 0;
    max-height: calc(70vh - 136px);
    overflow-y: scroll;
    padding-bottom: -1rem;
  }

  .Carrito .BoxCarrito ul .ItemCarrito {
    width: calc(100% - 2rem);
  }

  .Carrito .BoxCarrito .btn-carrito {
    max-width: 350px;
    margin-top: 0.5rem;
    margin-left: 0;
  }

  .Carrito .BoxCarrito .CarritoVacio {
    background-color: var(--Blanco);
    border-radius: 8px;
    padding-top: 1px;
    box-shadow: 0 4px 0 8px var(--Blanco);
  }

  .Carrito .ItemCarritoColum {
    background-color: transparent;
    width: 250px;
  }
  .Carrito .ItemCarritoColum .RowCenter {
    background-color: transparent;
    width: 250px;
  }

  .Carrito .ItemCarritoColum .RowCenter .BotonesMasomenos {
    background-color: transparent;
    margin: 0;
    padding: 0;
    width: 108px !important;
    display: grid;
    grid-template-columns: 32px 44px 32px;
  }
  .Carrito .ItemCarritoColum .RowCenter .BotonesMasomenos .BotonMenos,
  .Carrito .ItemCarritoColum .RowCenter .BotonesMasomenos .BotonMas {
    z-index: 1;
    margin: 0;
    padding: 0;
  }

  .CarritoDeleteItem {
    background-color: var(--Blanco) !important;
  }

  .NewPet {
    min-width: 375px !important;
  }
  .NewPet p {
    margin-top: 10px;
  }
  .NewPet h2 {
    color: black;
  }

  .CreditCard {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: flex-start;
    margin-left: -8px;

    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
  }

  .ActiveNuevaTC .CreditCard {
    max-height: 1024px;
  }

  .CreditCard .FormCreditCard {
    box-shadow: none;
    margin: 8px;
    padding: 0;
  }

  .CreditCard .FormCreditCard {
    background-color: transparent;
  }

  .CreditCardFront,
  .CreditCardEnd {
    height: 200px;
    max-width: 289px;
  }

  .CreditCard .FormCreditCard fieldset {
    background-color: transparent;
    margin: 0 8px 0 0;
    border-radius: 8px;
  }
}

/* Compu */
@media only screen and (min-width: 1366px) {
  .Recientes {
    width: 1124px;
  }
}

/*
LOS ESTILOS SE INTERPRETAN DE ARRIBA HACIA ABAJO
POR ENDE NO RECOMIENDO QUE PONGAN ESTILOS 
LUEGO DE LAS LINEAS DE MediaQuerys PORQUE
ES POSIBLE QUE SIN QUERER LAS SOBREESCRIBAS
*/

#titlediv {
  display: none;
}

/* AJSUTES AL CARRITO PERRI xD*/
.accionables {
  background-color: white;
  box-shadow: 0 32px 0 white;
}

.MobileBannerImage {
  grid-column: 1 / 4;
}

.MobileBannerImage img {
  width: calc(100% - 16px);
  margin: 36px 8px 0 8px;
  border-radius: 8px;
}

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

.DeskRecentProduct .titulo {
  color: white;
  text-align: center;
  font-size: 20px;
  margin-bottom: 8px;
}

.DeskRecentProduct .GridCuatro {
  display: grid;
  grid-template-columns: 258px 258px 258px 258px;
  justify-content: center;
  column-gap: 16px;
}

.DeskRecentProduct .Relleno {
  height: 150px;
}

.DeskRecentProduct .ProductBlock img {
  width: auto;
}

.DeskRecentProduct .ProductBlock {
  text-align: left;
  margin-bottom: 16px;
  height: 350px;
}

.DeskRecentProduct .ProductBlock:hover {
  box-shadow: 0 5px 15px -2px var(--SombraGlobal);
}

.DeskRecentProduct .ProductBlock .MotitoBox {
  display: none;
}

.DeskRecentProduct .ProductBlock b {
  padding: 0 0 0 1rem;
}

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

.BrandGrid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  justify-content: center;
  align-items: center;

  width: 100%;
  column-gap: 24px;
  row-gap: 32px;
}

.BrandGrid h4 {
  grid-column: 1 / 8;
  color: white;
  text-align: center;
  font-size: 24px;
}

.BrandGrid small {
  display: block;
  margin-top: 0;
  font-size: 16px;
  font-weight: normal;
}

.BrandGrid button {
  color: white;
  padding: 8px 44px;
  font-weight: bold;
  transition: all 0.3s;
  background-color: var(--AzulMP);
  border: 2px solid white;
  border-radius: 8px;
  font-size: 18px;
}

.BrandGrid:hover button {
  background-color: white;
  color: var(--AzulMP);
}

.BrandGrid .bubble {
  position: relative;
  background-color: white;
  width: 120px;
  height: 120px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px -2px var(--SombraGlobal);

  animation: xpand 0.3s;
  cursor: pointer;
  transition: all 0.3s;
}

.BrandGrid .bubble:hover {
  transform: scale(1.1);
}

.BrandGrid .bubble img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
}

.BrandGrid .Btn-Content {
  grid-column: 1/ 8;
  display: flex;
  justify-content: center;
}

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

.Comentario {
  background-color: rgb(255, 255, 255) !important;
  position: relative;
  padding: 8px;
  align-items: flex-start;
  max-width: 1080px;
  border-radius: 8px;
}

.Home_TCV .Comentario {
  /*  AGREGA MARGENES EN MOBILE */
  margin: 12px 8px 44px 8px;
}

.Home_TCV .CarouselComits {
  background-color: transparent;
  margin-bottom: -28px;
}

.Comentario img {
  width: 44px !important;
  height: 44px !important;
  margin: 0 8px;
  border-radius: 50% !important;
}

.Comentario-Head {
  background-color: transparent;
  display: grid;
  grid-template-columns: 60px auto;
  margin: -4px -8px 8px -8px;
  align-items: center;
}

.Comentario-Head h5 {
  background-color: transparent;
  text-align: left;
  padding-bottom: 4px;
}

.Comentario-Head .estrellas {
  display: flex;
}

.Comentario-Head .estrellas svg {
  margin-right: 4px;
}

.Comentario p {
  font-style: italic;
}

.informativos {
  width: 80vw;
  margin: auto;
}

.GridTres {
  display: grid;
  grid-template-columns: 350px 350px 350px;
  column-gap: 16px;
  justify-content: center;
}

.informativos .GridTres img {
  width: 100%;
  box-shadow: 0 5px 15px -2px var(--SombraGlobal);
  border-radius: 8px;
}

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
.informativos h4,
.CarouselComits h4 {
  text-align: center;
  color: white;
  font-size: 20px;
  margin: 0 0 0 0;
}

.HomeDeskBanner img {
  max-width: 1080px;
  margin: 0 auto 44px auto;
}

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

.informativos {
  width: 100%;
  max-width: 1080px;
}

.informativos .GridDos {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 16px;

  width: 100%;
  max-width: 1080px;

  margin: 16px auto 44px auto;
}

.informativos .GridDos img {
  width: 100%;
}

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

.CategoriasDesk {
  padding-top: 64px;
}

.CategoriasDesk article {
  display: grid;
  grid-template-columns: 166px 166px 166px 166px 166px 166px;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
}

.Categorias {
  margin: 0 0 32px 0;
}

.Categorias h4 {
  color: var(--Blanco);
  text-align: center;
  margin-bottom: 12px;
  font-size: 20px;
}

.Categorias .CatBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 4px 8px;
  margin: 6px 0;
  transition: all 0.3s;
  cursor: pointer;
}

.Categorias .CatBox:hover {
  transform: scale(1.1);
}

.Categorias .CatBox img {
  max-height: 20vw;
}

.CategoriasDesk .CatBox img {
  max-width: 100px;
}

.CategoriasDesk .CatBox {
  height: 180px;
}

.Categorias .CatBox h5 {
  margin: 8px 0 0 0;
  font-size: 12px;
}

.CategoriasDesk .CatBox h5 {
  font-size: 16px;
}

.CloseBubble {
  height: 32px;
  width: 32px;
}

.OdeOliver {
  height: 32px;
  width: 32px;
}

.zsiq_floatmain {
  height: 0;
  width: 0;
  position: absolute;
  right: -70px !important;
}

.zsiq_float {
  position: fixed;
  bottom: 20vw;
  right: 8px !important;
}

.HomeDesk .Canvas {
  min-height: 0;
  margin-top: 0;

  animation-name: aparece;
  animation-duration: 1s;
}

.HomeDesk .VetsList ol {
  max-width: 1080px;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 16px;
}

.HomeDesk .SeguroPage {
  max-width: 1080px;
  max-height: 250px;
  margin: auto;
  padding: 0;

  animation-name: aparece;
  animation-duration: 1s;
}

.HomeDesk .SeguroPage .SlideBox {
  width: auto;
}
